import {
  useBreakpoints,
  useLoadingHook,
  useManagingStep,
} from '@sweb-front/hooks';
import {
  getJSONDataFromStorage,
  isStringEmpty,
  trackEvent,
} from '@sweb-front/utils';
import { ERRORPAGE, SOLVA, STOREDSOLVAKEY } from '@vat/configuration';
import { formatNumber, useOverrideShadowElementStyle } from '@vat/utils';
import { GenderInputDS, IListItem } from '@sweb-front/components';
import {
  IOpportunityState,
  updateParcoursNavigation,
  useAppDispatch,
} from '@sweb-front/store';
import { useState, useEffect, useCallback, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isInputValidToPattern,
  scrollToFirstInvalidElement,
  roundValue,
  postData,
  isValueZero,
  setIdHashed,
} from '../utils';
import { SECTORLIST } from './IncomeExpensesConstants';
import { SECTORCODES } from './IncomeExpensesConstants';
import { useNavigate } from 'react-router-dom';

export interface IValues {
  activitySector: string;
  monthlyIncomeAndExpense?: string;
  monthNumberBaseCalculation?: string;
  monthlyExpenses?: string;
}

export const pattern =
  '(^[0-9]{1,3})([,.]{1,1}[0-9]{1,2})?$|(^[0-9]{1,1} ?[0-9]{3,3})([,.]{1,1}[0-9]{1,2})?$|(^[0-9]{2,2} ?[0-9]{3,3})([,.]{1,1}[0-9]{1,2})?$';

// Check on by one the validity of the field
export const isFieldInvalid = (
  field: string,
  value: string | undefined,
  ptn?: string
) => {
  if (['monthlyExpenses', 'monthlyIncomeAndExpense'].includes(field)) {
    return isStringEmpty(value) || !isInputValidToPattern(value, ptn ?? '');
  }

  return isStringEmpty(value);
};

// Check form validity within field invalidities
export const checkFormValidity = (
  fields: IValues,
  showBaseMonthIncome: boolean
) => {
  const fieldToCheck = {
    ...fields,
  } as Record<string, string>;
  if (!showBaseMonthIncome) {
    delete fieldToCheck.monthNumberBaseCalculation;
  }
  return !Object.keys(fieldToCheck).some((field) =>
    isFieldInvalid(field, fieldToCheck[field], pattern)
  );
};

export const onSetInvalidityFieldState = (
  fieldValues: IValues,
  ptn: string
) => {
  return {
    activitySector: isFieldInvalid(
      'activitySector',
      fieldValues.activitySector
    ),
    monthlyIncomeAndExpense: isFieldInvalid(
      'monthlyIncomeAndExpense',
      fieldValues.monthlyIncomeAndExpense,
      ptn
    ),
    monthNumberBaseCalculation: isFieldInvalid(
      'monthNumberBaseCalculation',
      fieldValues.monthNumberBaseCalculation
    ),
    monthlyExpenses: isFieldInvalid(
      'monthlyExpenses',
      fieldValues.monthlyExpenses,
      ptn
    ),
  };
};

export const getInvalidityState = (
  field: string,
  value: string | null | undefined,
  invalidityFieldsStates: any,
  ptn: string
) => {
  if (invalidityFieldsStates[field] === null) {
    return null;
  }

  if (['monthlyExpenses', 'monthlyIncomeAndExpense'].includes(field)) {
    return isStringEmpty(value) || !isInputValidToPattern(value, ptn);
  }

  return isStringEmpty(value);
};

const useIncomeExpenseForm = (
  opportunity: IOpportunityState,
  codeParameters: string,
  formRef?: RefObject<HTMLFormElement>,
  baseSalaryMonthRef?: RefObject<GenderInputDS>
) => {
  const { t } = useTranslation();
  const { goBackToStep, goToNextStep } = useManagingStep();
  const [nbClickOnSubmit, setNbClickOnSubmit] = useState(0);
  const [isFormValid, setIsFormValid] = useState<boolean>();
  const { person } = opportunity;
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const [isUnMount, setIsUnMount] = useState(false);
  // We must add some states to handle the bubble info state because of events behaviour of the input
  const [showBubbleInfo, setShowBubbleInfo] = useState(false);
  const [isOnMouseLeave, setIsOnMouseLeave] = useState<boolean>();
  // State for the base month selection
  const [showBaseMonthIncome, setShowBaseMonthIncome] = useState(false);
  // Behaviour for expenses, same as the income
  const [showExpensesBubbleInfo, setShowExpensesBubbleInfo] = useState(false);
  const [isOnMouseLeaveFromExpense, setIsOnMouseLeaveFromExpense] =
    useState<boolean>();

  const { manageNumericInput } = useOverrideShadowElementStyle();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsUnMount(false);
    return () => {
      setIsUnMount(true);
    };
  }, []);

  const storedValues = getJSONDataFromStorage(
    localStorage.getItem('token') ?? '',
    STOREDSOLVAKEY
  ) as IValues;

  useEffect(() => {
    if (
      storedValues &&
      !isStringEmpty(storedValues.monthNumberBaseCalculation) &&
      !isStringEmpty(storedValues.monthlyIncomeAndExpense) &&
      parseInt(
        storedValues.monthlyIncomeAndExpense?.replace(/ /gi, '') ?? '',
        10
      ) > 0
    ) {
      setShowBaseMonthIncome(true);
    }
  }, []);

  const [values, setValues] = useState<IValues>({
    activitySector: storedValues?.activitySector ?? '',
    monthlyIncomeAndExpense:
      storedValues && storedValues.monthlyIncomeAndExpense
        ? `${formatNumber(parseInt(storedValues.monthlyIncomeAndExpense, 10))}`
        : '',
    monthNumberBaseCalculation:
      formatNumber(storedValues?.monthNumberBaseCalculation ?? '') ?? '',
    monthlyExpenses:
      storedValues && storedValues.monthlyExpenses
        ? `${formatNumber(parseInt(storedValues?.monthlyExpenses, 10))}`
        : '',
  });

  // Manage form field validation
  const [invalidityFieldsStates, setInvalidityFieldStates] = useState<
    Record<string, boolean | null>
  >({
    activitySector: null,
    monthlyIncomeAndExpense: null,
    monthNumberBaseCalculation: null,
    monthlyExpenses: null,
  });

  useEffect(() => {
    setIsFormValid(checkFormValidity(values, showBaseMonthIncome));
    return () => {};
  }, [values, showBaseMonthIncome]);

  useEffect(() => {
    if (formRef?.current) {
      scrollToFirstInvalidElement(formRef);
    }
  }, [formRef?.current, nbClickOnSubmit]);

  const onSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isActionLoading) {
        return;
      }
      setNbClickOnSubmit((prev) => prev + 1);
      setInvalidityFieldStates(onSetInvalidityFieldState(values, pattern));
      if (
        parseInt(
          (values?.monthlyIncomeAndExpense ?? '').replace(/ /gi, ''),
          10
        ) > 0 &&
        isStringEmpty(values.monthNumberBaseCalculation)
      ) {
        baseSalaryMonthRef?.current?.setAttribute('invalid', 'true');
        baseSalaryMonthRef?.current?.removeAttribute('valid');
      }
      if (isFormValid) {
        updateIsActionLoading(true);
        const inputData = {
          ...values,
          monthlyIncomeAndExpense: roundValue(
            values.monthlyIncomeAndExpense ?? ''
          )?.replace(' ', ''),
          monthlyExpenses: roundValue(values.monthlyExpenses ?? '')?.replace(
            ' ',
            ''
          ),
        };
        const income = inputData?.monthlyIncomeAndExpense ?? '';
        if (!isStringEmpty(localStorage.getItem('token'))) {
          localStorage.setItem(
            STOREDSOLVAKEY,
            JSON.stringify({
              [localStorage.getItem('token') ?? '']: {
                activitySector: inputData.activitySector,
                monthlyIncomeAndExpense: income,
                monthNumberBaseCalculation:
                  parseInt((income ?? '').replace(/ /gi, ''), 10) > 0
                    ? inputData.monthNumberBaseCalculation
                    : null,
                monthlyExpenses: inputData?.monthlyExpenses ?? '',
              },
            })
          );
        }

        postData(
          'vendors/opportunities/v1/opportunity',
          {
            persons: [
              {
                personId: person.personId,
                incomes: [
                  {
                    incomeAmt: parseInt(
                      inputData.monthlyIncomeAndExpense ?? '',
                      10
                    ),
                    incomeTypeCd: 'SAL',
                    incomeNb:
                      parseInt(income.replace(/ /gi, ''), 10) > 0
                        ? inputData.monthNumberBaseCalculation
                        : null,
                  },
                ],
                outgoings: [
                  {
                    outgoingAmt: parseInt(inputData.monthlyExpenses ?? '', 10),
                    outgoingTypeCd: 'AGR',
                  },
                ],
                profession: {
                  ihmSector: inputData.activitySector,
                },
              },
            ],
          },
          () => {
            dispatch(
              updateParcoursNavigation({
                name: SOLVA,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            updateIsActionLoading(false);
            goToNextStep();
          },
          () => {
            dispatch(
              updateParcoursNavigation({
                name: SOLVA,
                loaded: true,
                actionPageDone: true,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateIsActionLoading(false);
            navigate(ERRORPAGE);
          }
        );
      }
    },
    [values, isFormValid, person, opportunity, isActionLoading]
  );

  const onChange = useCallback(
    (e: Event, field: string) => {
      const { value } = (e as unknown as React.ChangeEvent<HTMLInputElement>)
        .target;
      let val: string | null | undefined = value;

      if (field === 'monthlyIncomeAndExpense' && !isOnMouseLeave) {
        setShowBubbleInfo(!isValueZero(value));
      }

      if (field === 'monthlyExpenses' && !isOnMouseLeaveFromExpense) {
        setShowExpensesBubbleInfo(!isValueZero(value));
      }

      if (isOnMouseLeave && field === 'monthlyIncomeAndExpense') {
        setShowBaseMonthIncome(!isValueZero);
        if (isStringEmpty(value) || isValueZero(value)) {
          setValues((prev) => ({
            ...prev,
            monthNumberBaseCalculation: '',
          }));
        }
        val = roundValue(value);
      }

      if (isOnMouseLeaveFromExpense && field === 'monthlyExpenses') {
        val = roundValue(value);
      }

      if (!isUnMount) {
        setInvalidityFieldStates((prevVal) => ({
          ...prevVal,
          [field]: getInvalidityState(
            field,
            val,
            invalidityFieldsStates,
            pattern
          ),
        }));

        setValues((prevValues) => ({
          ...prevValues,
          [field]: val,
        }));
      }
    },
    [
      invalidityFieldsStates,
      isOnMouseLeave,
      isOnMouseLeaveFromExpense,
      isUnMount,
    ]
  );

  // for desktop
  const onActivitySectorChange = useCallback((value?: string) => {
    onChange({ target: { value } } as unknown as Event, 'activitySector');
  }, []);

  // for mobile
  const onSectorChange = useCallback((value: string) => {
    setValues((prev) => ({
      ...prev,
      activitySector: value,
    }));
    setInvalidityFieldStates((prev) => ({
      ...prev,
      activitySector: isFieldInvalid('activitySector', value),
    }));
  }, []);

  const onChangeSector = useCallback((e: any, item?: IListItem) => {
    onSectorChange(item?.value as string);
  }, []);

  const onIncomeChange = useCallback(
    (e: Event) => {
      const { value } = (e as unknown as React.ChangeEvent<HTMLInputElement>)
        .target;
      if (isValueZero(value)) {
        onChange(e, 'monthlyIncomeAndExpense');
        setValues((prev) => ({
          ...prev,
          monthNumberBaseCalculation: '',
        }));
        baseSalaryMonthRef?.current?.removeAttribute('invalid');
        baseSalaryMonthRef?.current?.removeAttribute('valid');
        setShowBubbleInfo(!isValueZero(value));
        setShowBaseMonthIncome(!isValueZero(value));
      }
    },
    [baseSalaryMonthRef]
  );

  const onIncomeLetterChanged = useCallback((e: Event) => {
    setShowBaseMonthIncome(true);
    setIsOnMouseLeave(false);
    onChange(e, 'monthlyIncomeAndExpense');
  }, []);

  const onIncomeFocus = useCallback((e: Event) => {
    const { value } = (e as unknown as React.ChangeEvent<HTMLInputElement>)
      .target;
    setShowBaseMonthIncome(!isValueZero(value));
    setShowBubbleInfo(true);
  }, []);

  const onIncomeBlur = useCallback((e: Event) => {
    const { value } = (e as unknown as React.ChangeEvent<HTMLInputElement>)
      .target;
    setShowBaseMonthIncome(!(isStringEmpty(value) || isValueZero(value)));
    setShowBubbleInfo(false);
    setIsOnMouseLeave(true);
    manageNumericInput('input-monthlyIncomeAndExpense');
  }, []);

  const onExpensesChange = useCallback((e: Event) => {
    const { value } = (e as unknown as React.ChangeEvent<HTMLInputElement>)
      .target;
    if (parseInt(value, 10) === 0) {
      setShowExpensesBubbleInfo(false);
      onChange(e, 'monthlyExpenses');
    }
  }, []);

  const onExpensesLetterChanged = useCallback((e: Event) => {
    setIsOnMouseLeaveFromExpense(false);
    onChange(e, 'monthlyExpenses');
  }, []);

  const onExpensesFocus = useCallback(() => {
    setShowExpensesBubbleInfo(true);
  }, []);

  const onExpensesBlur = useCallback(() => {
    setShowExpensesBubbleInfo(false);
    setIsOnMouseLeaveFromExpense(true);
    manageNumericInput('input-monthlyExpenses');
  }, []);

  useEffect(() => {
    if (showBaseMonthIncome) {
      baseSalaryMonthRef?.current?.removeAttribute('invalid');
      baseSalaryMonthRef?.current?.removeAttribute('valid');
    }
  }, [baseSalaryMonthRef, showBaseMonthIncome]);

  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: codeParameters,
        pageName: 'E-Commerce : Formulaire Solva',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails?.tncRt as number) ?? undefined,
        Term: (financialDetails?.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity.distributor.distributorNb),
      });
    }
  }, []);

  const mobileSectors = SECTORLIST.map((sector) => ({
    value: SECTORCODES[sector],
    displayValue: t(`incomesAndExpenses.form.sectorList.${sector}`),
  }));

  const { isDesktop } = useBreakpoints();

  // we put all the methods and variables useful for testing for the return of this hook
  return {
    isDesktop,
    showBubbleInfo,
    showExpensesBubbleInfo,
    values,
    showBaseMonthIncome,
    isFormValid,
    isOnMouseLeave,
    isOnMouseLeaveFromExpense,
    invalidityFieldsStates,
    nbClickOnSubmit,
    isActionLoading,
    mobileSectors,
    onChangeSector,
    onActivitySectorChange,
    setIsOnMouseLeave,
    setIsOnMouseLeaveFromExpense,
    setInvalidityFieldStates,
    setIsFormValid,
    onSubmit,
    onChange,
    goBackToStep,
    getInvalidityState,
    onSectorChange,
    // Income
    onIncomeChange,
    onIncomeLetterChanged,
    onIncomeFocus,
    onIncomeBlur,
    // Monthly expense
    onExpensesChange,
    onExpensesLetterChanged,
    onExpensesFocus,
    onExpensesBlur,
    // Base month calculation
    scrollToFirstInvalidElement,
  };
};

export default useIncomeExpenseForm;
