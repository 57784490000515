import { BaseInput, Replacement } from '../DesignSystem';

export type InputDSWrapperProps = {
  id: string;
  value?: string;
  onChange: (value?: string) => void;
  errorMessage?: string;
  label: string;
  placeholder: string;
  onBlur?: () => void;
  onFocus?: () => void;
  isInvalid?: boolean;
  replacement: string | Replacement;
  maxlength?: number;
  withReset?: boolean | null;
};

export const InputDSWrapper = ({
  id,
  value,
  onChange,
  errorMessage,
  label,
  placeholder,
  onBlur,
  onFocus,
  isInvalid,
  replacement,
  maxlength,
  withReset = null,
}: InputDSWrapperProps) => {
  const computeOnChange = (e: Event) => {
    const v = (e.target as unknown as { value?: string }).value;
    onChange(v);
  };
  return (
    <BaseInput
      id={id}
      className="container"
      type="text"
      mobileType="text"
      label={label}
      placeholder={placeholder}
      onInputChange={computeOnChange}
      value={value ? String(value) : ''}
      onBlur={onBlur}
      onFocus={onFocus}
      customValidityPattern={JSON.stringify(
        isInvalid
          ? [
              {
                regexp: '.+',
                errorMessage,
              },
            ]
          : []
      )}
      replacement={replacement}
      maxlength={maxlength}
      with-round
      can-delete
      with-reset={withReset}
    />
  );
};
