import { BoldSpan, TextBlock, TextLine } from './styles';
import { ReactNode } from 'react';

export interface ICustomMentionLegalesProps {
  data: Partial<{
    socialEntityName: string;
    capitalAmount: string;
    entityPlace: string;
    rcsNumber: string;
    phoneNumber: string;
    publishedAuthor: string;
    prudentialEntity: string;
    oriasNumber: string;
  }>;
}

const buildTextline = (value: ReactNode, title?: string) => {
  return !value || value === null ? (
    <></>
  ) : (
    <TextLine>
      {title && <BoldSpan>{title}</BoldSpan>} {value}
    </TextLine>
  );
};

const buildTextblock = (value: ReactNode) => {
  return !value || value === null ? <></> : <TextBlock>{value}</TextBlock>;
};

const CustomMentionsLegales = ({ data }: ICustomMentionLegalesProps) => {
  const {
    socialEntityName = '',
    capitalAmount = '',
    entityPlace = '',
    oriasNumber = '',
    rcsNumber = '',
    phoneNumber = '',
    publishedAuthor = '',
    prudentialEntity = '',
  } = data;
  return (
    <>
      <TextBlock>
        {buildTextline(socialEntityName, 'Dénomination sociale :')}
        {buildTextline(`${capitalAmount} €`, 'Société Anonyme au capital de :')}
        {buildTextline(entityPlace, 'Siège social :')}
        {buildTextline(rcsNumber, 'RCS :')}
        {buildTextline(
          <>
            Intermédiaire en assurance immatriculé sous le N°Orias
            {` ${oriasNumber} `}(
            <a target="_blank" rel="noopener noreferrer" href="www.orias.fr">
              www.orias.fr
            </a>
            )
          </>
        )}
        {buildTextline(phoneNumber, 'Numéro de téléphone :')}
        {buildTextline(publishedAuthor, 'Directeur de la publication :')}
      </TextBlock>
      {buildTextblock(
        <>
          {socialEntityName} est une société de financement de droit français
          agréée par l'autorité de contrôle Prudentiel et de Résolution (
          {prudentialEntity}) et contrôlée par cette même autorité.
        </>
      )}
      {buildTextblock(<>Code TVA : FR 75 341 891 653</>)}
      {buildTextblock(
        <>
          {socialEntityName} exerce ses activités conformément aux dispositions
          des articles L. 311-1 et suivants du Code monétaire et financier, aux
          dispositions des articles L. 511-1 et suivants du même Code et aux
          règlements applicables.
        </>
      )}

      {buildTextblock(
        <>
          {socialEntityName} est membre de l'Association Française des Sociétés
          Financières. Ce site est hébergé sur les serveurs de BNP Paribas
          Personal Finance.
          {buildTextline('Société Anonyme au capital de 583.834.454 €')}
          {buildTextline(
            'Siège social : 1 Boulevard Haussmann – 75009 Paris France '
          )}
          {buildTextline('RCS : Paris n°542 097 902')}
        </>
      )}
    </>
  );
};

export default CustomMentionsLegales;
