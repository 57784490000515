import { Centering, Theme } from '@sweb-front/components';
import { GlobalStyles } from '@sweb-front/styles';
import { Footer } from '@vat/components';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TParamWaitingPage } from '@sweb-front/types';

import {
  COOKIES,
  ERRORPAGE,
  LIVENESS,
  MAINTENANCE,
  MENTIONSLEGALES,
  MENTIONSLEGALES100,
  MENTIONSLEGALES140,
  OBCONNEXIONERROR,
  OPENBANKING,
  REDIRECTION,
  REFUSEDREPONSE,
  SMSMENTIONLEGALE,
  SOLVARIB,
  STOREDASSUKEY,
  STOREDPRFKEY,
  STOREDSOLVAKEY,
  SUCCESSREPONSE,
  WAITINGRESPONSE,
  getMatchingComponents,
} from '@vat/configuration';

import { AppOptionsProvider, PageLoadingContext } from '@vat/utils';

import { axiosInstance } from '@sweb-front/services';
import axios from 'axios';
import {
  IStep,
  useAppSelector,
  selectSteps,
  selectNavigation,
} from '@sweb-front/store';
import useApp from './useApp';
import {
  AppCenterContainer,
  AppWrapper,
  CenteringHeaderWrapper,
  RouteWrapper,
} from './styles';

/**
 * Compute the step to display on the stepline with its new step number
 * @param {IStep[]} steps
 * @return {IStep[]}
 */
export const computeSteps = (steps: IStep[]): IStep[] => {
  return (steps ?? [])
    .filter((s) => !s.isSubStep)
    .map((s, index: number) => ({
      ...s,
      step: index + 1,
    }));
};

export const buildRouteFromSteps = (stepList?: IStep[]) => {
  return (stepList ?? []).map(({ components, paths }) =>
    paths?.map((path) => {
      return (
        <Route
          key={path}
          path={path}
          element={getMatchingComponents(components) as any}
        />
      );
    })
  );
};

export const regexp = new RegExp('/to/(.*)+', 'i');

export const clearLocalStorageData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem(STOREDSOLVAKEY);
  localStorage.removeItem(STOREDASSUKEY);
  localStorage.removeItem(STOREDPRFKEY);
  localStorage.removeItem(SOLVARIB);
};

export const fetchOpportunityFromBase = async (
  token: string,
  callbackSuccess: (result: any) => void,
  callbackError: (e: any) => void
) => {
  axios.defaults.headers.common.token = token;
  try {
    const response = await axiosInstance().get(
      `vendors/opportunities/v1/opportunity`
    );
    callbackSuccess(response);
    return response;
  } catch (e) {
    return callbackError(e);
  }
};

export const getStickyMessage = (url: string) => {
  let title = 'errorPage.sticky.title';
  let message = 'errorPage.sticky.message';

  if ([SUCCESSREPONSE].includes(url)) {
    title = 'responses.accepted.sticky.title';
    message = 'responses.accepted.sticky.message';
  } else if (REFUSEDREPONSE.includes(url)) {
    title = 'responses.refused.sticky.title';
    message = 'responses.refused.sticky.message';
  } else if (WAITINGRESPONSE.includes(url)) {
    title = 'responses.maybe.sticky.title';
    message = 'responses.maybe.sticky.message';
  }

  return {
    title,
    message,
  };
};

const Header = lazy(() =>
  import('@vat/layouts').then((module) => ({
    default: module.Header,
  }))
);

const Logo = lazy(() =>
  import('@vat/layouts').then((module) => ({
    default: module.Logo,
  }))
);

const OpenBanking = lazy(() =>
  import('@vat/layouts').then((module) => ({
    default: module.OpenBanking,
  }))
);

const Redirection = lazy(() =>
  import('@vat/layouts').then((module) => ({
    default: module.Redirection,
  }))
);

const ErrorBoundary = lazy(() =>
  import('@vat/components').then((module) => ({
    default: module.ErrorBoundary,
  }))
);
const ErrorPage = lazy(() =>
  import('@sweb-front/components').then((module) => ({
    default: module.ErrorPage,
  }))
);
const RedirectionComponent = lazy(() =>
  import('@sweb-front/components').then((module) => ({
    default: module.RedirectionComponent,
  }))
);
const ApprovedPayment = lazy(() => import('../ApprovedPayment'));
const MaintenancePage = lazy(() => import('../MaintenancePage'));
const ApprovedLoan = lazy(() => import('../ApprovedLoan/ApprovedLoan'));
const Cookies = lazy(() => import('../Cookies/Cookies'));
const MentionsLegales = lazy(() => import('../MentionsLegales'));
const RefusedPayment = lazy(() => import('../RefusedPayment'));
const SmsMentionLegale = lazy(() => import('../SmsMentionLegale'));
const WaitingPage = lazy(() => import('../WaitingPage'));
const Liveness = lazy(() => import('../Liveness'));
const ObConnexionError = lazy(
  () =>
    import(
      'src/containers/forms/OpenBanking/items/ObConnexionError/ObConnexionError'
    )
);

const App: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const steps = useAppSelector(selectSteps);
  const opposedCommercialOffers = useAppSelector(
    (state) => state?.opportunity?.state?.person?.address?.useBo
  );
  const ihmSector = useAppSelector(
    (state) => state?.opportunity?.state?.person?.profession?.ihmSector
  );
  const appNavigation = useAppSelector(selectNavigation);
  const storedToken = localStorage.getItem('token');
  const psp = (window as unknown as WindowWithEnv)._env_?.psp;

  const {
    t,
    theme,
    appOptions,
    isErrorOccured,
    isPageWithNoHeader,
    lastPage,
    isAppLoading,
    numVendor,
    LoaderCmp,
    isWaitingPage,
    waitingStep,
    containedError,
    showHeader,
    showFooter,
    setAppOptions,
    updatePageIsLoading,
    currentStepWithSubStep,
    computeStepsWithSubSteps,
  } = useApp(searchParams, steps, appNavigation, storedToken, ihmSector);

  return (
    <Theme theme={theme}>
      <AppOptionsProvider value={{ appOptions, setAppOptions }}>
        <AppWrapper data-testid="AppWrapper">
          <PageLoadingContext.Provider value={updatePageIsLoading}>
            <AppCenterContainer data-testid="AppCenterContainer">
              {showHeader && !isAppLoading && (
                <CenteringHeaderWrapper>
                  <Logo numVendor={numVendor} />
                  <Header
                    steps={computeStepsWithSubSteps(steps)}
                    currentStep={currentStepWithSubStep}
                  />
                </CenteringHeaderWrapper>
              )}
              <GlobalStyles />
              <base-ds-guidelines />
              <ErrorBoundary>
                <Centering>
                  {isErrorOccured && <ErrorPage />}
                  {isAppLoading && LoaderCmp}
                  {!isErrorOccured && !isAppLoading && (
                    <RouteWrapper>
                      <Suspense fallback={LoaderCmp}>
                        {!isPageWithNoHeader &&
                        steps?.length <= 0 &&
                        !isWaitingPage ? (
                          LoaderCmp
                        ) : (
                          <Routes>
                            {buildRouteFromSteps(steps)}
                            <Route
                              path="/to/:waitingStep"
                              element={
                                <WaitingPage
                                  waitingStep={waitingStep}
                                />
                              }
                            ></Route>
                            <Route
                              path={SUCCESSREPONSE}
                              element={<ApprovedPayment />}
                            />
                            <Route
                              path={REFUSEDREPONSE}
                              element={<RefusedPayment />}
                            />
                            <Route
                              path={MENTIONSLEGALES}
                              element={<MentionsLegales />}
                            />
                            <Route
                              path={MENTIONSLEGALES100}
                              element={<MentionsLegales companyCode={100} />}
                            />
                            <Route
                              path={MENTIONSLEGALES140}
                              element={<MentionsLegales companyCode={140} />}
                            />
                            <Route path={COOKIES} element={<Cookies />}></Route>
                            <Route
                              path={WAITINGRESPONSE}
                              element={<ApprovedLoan />}
                            />
                            <Route
                              path={SMSMENTIONLEGALE}
                              element={<SmsMentionLegale />}
                            />

                            <Route
                              path={OPENBANKING}
                              element={<OpenBanking />}
                            />
                            <Route
                              path={OBCONNEXIONERROR}
                              element={<ObConnexionError />}
                            />
                            <Route
                              path={LIVENESS}
                              element={
                                <Liveness
                                  token={localStorage.getItem('token')}
                                />
                              }
                            />
                            <Route
                              path={REDIRECTION}
                              element={<Redirection />}
                            />
                            <Route path={ERRORPAGE} element={<ErrorPage />} />
                            <Route
                              path={MAINTENANCE}
                              element={<MaintenancePage />}
                            />
                            <Route
                              path="*"
                              element={
                                steps?.length <= 0 && !containedError ? (
                                  <></>
                                ) : (
                                  <>
                                    <ErrorPage />
                                  </>
                                )
                              }
                            />
                          </Routes>
                        )}
                        {showFooter && (
                          <Footer
                            opposedCommercialOffers={opposedCommercialOffers}
                            canDirectlyShowCmp={[
                              SUCCESSREPONSE,
                              REFUSEDREPONSE,
                              WAITINGRESPONSE,
                            ].includes(lastPage)}
                          />
                        )}
                      </Suspense>
                    </RouteWrapper>
                  )}
                </Centering>
              </ErrorBoundary>
            </AppCenterContainer>
          </PageLoadingContext.Provider>
          {/*
            Displays when the the current page is a response page
            or an error occured or the session is expired
           */}
          {([REFUSEDREPONSE, WAITINGRESPONSE, SUCCESSREPONSE].includes(
            lastPage
          ) ||
            ERRORPAGE === lastPage ||
            isErrorOccured) && (
            <RedirectionComponent
              delay={psp?.[appNavigation?.vendorId] ?? 60}
              title={t(getStickyMessage(lastPage).title)}
              message={t(getStickyMessage(lastPage).message)}
              currentPage={lastPage}
              isErrorOccuredInPage={isErrorOccured}
            />
          )}
        </AppWrapper>
      </AppOptionsProvider>
    </Theme>
  );
};

export default App;
