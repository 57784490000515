import { Title } from '@sweb-front/components';
import { ButtonWrapper } from '@sweb-front/styles';
import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  width: 100%;
  margin: 1.6rem 0;
  & gender-input {
    display: flex;
    font-size: 1.6rem;
    & selected-button {
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2.4rem;
      flex-grow: 1;
      padding: 1.5rem 0;
    }
  }
`;

export const FirstInputWrapper = styled(InputWrapper)`
  margin: 0.8rem 0 1.6rem 0;
  & gender-input#input-civility {
    & selected-button {
      --ripple-color: ${({ theme }) => theme.vendor.colors.light};
    }

    & selected-button[selected] {
      background-color: ${({ theme }) => theme.vendor.colors.light};
      border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
      color: ${({ theme }) => theme.vendor.colors.primary};
    }
    & selected-button::active {
      ${({ theme }) => theme.vendor.colors.light};
    }
  }
`;

export const LastInputWrapper = styled.div`
  margin: 1.6rem 0 2.4rem 0;
`;

export const TextWrapper = styled.div`
  width: 75%;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const EditableInputWrapper = styled.div`
  margin-top: 1.6rem;
  padding: 0;

  &:first-of-type {
    margin-bottom: 0.4rem;
  }

  &:last-of-type {
    margin-top: 0;
  }
`;

export const MNAIInputWrapper = styled(InputWrapper)`
  margin-top: 0;
`;

export const InformationButtonWrapper = styled(ButtonWrapper)`
  @media screen and ${TAB} {
    align-items: left;
  }
`;

export const SVGIconWrapper = styled.div`
  align-self: center;
  & base-icon {
    margin-right: 0.8rem;
    --color-icon: var(--information-500);
    --icon-width: 6rem;
    --icon-height: 6rem;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 2rem;
`;

export const TitleWrapper = styled(Title)`
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
  font-size: 1.8rem;
  color: var(--neutrals-700);
`;

export const DescriptionWrapper = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-500);
  & .strong {
    margin-top: 1.2rem;
  }
`;

export const ButtonModalWrapper = styled.div`
  display: flex;
  margin: 1.6rem 2rem 0rem 2rem;
  flex-direction: column-reverse;
  gap: 1.2rem;
  & button {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 2rem 0rem;
    height: 3.6rem;
    width: auto;
    &:first-of-type {
      color: ${({ theme }) => theme.vendor.colors.primary};
      background: transparent;
      box-shadow: none;
    }
  }
`;

export const BoldTitleWrapper = styled.div`
  font-family: Open Sans Bold;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: left;
`;
