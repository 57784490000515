import styled from 'styled-components';

export const SectorListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0 1.6rem 0;
  & gender-input {
    & selected-button {
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 2.4rem 1.6rem;
      text-align: center;
      width: 47.2%;
      height: 5.6rem;
    }
  }
`;

export const IncomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0;
  & numeric-input {
    --ripple-color: ${({ theme }) => theme.vendor.colors.light};
    flex-grow: 1;
  }
`;

export const BaseMonthIncomeNumberWrapper = styled.div`
  margin: 2.8rem 0 2.4rem 0;
  display: flex-row;
  flex-direction: row;

  & selected-button {
    margin-right: 0.4rem;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    flex-grow: 1;
    min-width: 3.2rem;
    padding: 1.7rem 0;
    color: var(--neutrals-500);
    --ripple-color: ${({ theme }) => theme.vendor.colors.light};
  }

  & selected-button[selected] {
    color: ${({ theme }) => theme.vendor.colors.primary};
    background: ${({ theme }) => theme.vendor.colors.light};
    border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
  }

  & selected-button:last-of-type {
    margin-right: 0;
  }
`;

export const IncomeDetailsInfoWrapper = styled.div`
  margin: 0;
  margin-top: 0.8rem;
`;
