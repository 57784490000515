/* eslint-disable consistent-return */
import { useLoadingHook, useManagingStep } from '@sweb-front/hooks';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useOverrideShadowElementStyle } from '@vat/utils';
import {
  IOpportunityState,
  setFamilySituation,
  updateParcoursNavigation,
} from '@sweb-front/store';
import { useAppDispatch, useAppSelector } from '@sweb-front/store';
import { trackEvent } from '@sweb-front/utils';
import { postData, setIdHashed } from '../utils';
import { MAX_NB_OF_CHILDREN } from './SituationFamilialeForm';
import { ERRORPAGE, SITUATIONFAMILIALE } from '@vat/configuration';
import { useNavigate } from 'react-router-dom';

type SituationFamilialeOptions = {
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
};

const useSituationFamilialeForm = (
  opportunity: IOpportunityState,
  options: SituationFamilialeOptions
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.parameters.state);
  useOverrideShadowElementStyle();

  const validateOnBlur = options?.validateOnBlur || true;
  const validateOnChange = options?.validateOnChange || true;
  const { person } = opportunity;
  const { goBackToStep, goToNextStep } = useManagingStep();
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const navigate = useNavigate();
  const situationFamilialeSchema = z.object({
    familySituation: z
      .string({
        required_error: t('situationFamiliale.familySituation.errors.required'),
        invalid_type_error: t(
          'situationFamiliale.familySituation.errors.required'
        ),
      })
      .nonempty(t('situationFamiliale.familySituation.errors.required')),
    nbOfChildren: z
      .number({
        required_error: t('situationFamiliale.nbOfChildren.errors.required'),
        invalid_type_error: t(
          'situationFamiliale.nbOfChildren.errors.required'
        ),
      })
      .nonnegative(t('situationFamiliale.nbOfChildren.errors.required'))
      .lte(MAX_NB_OF_CHILDREN, t('situationFamiliale.nbOfChildren.errors.max')),
  });
  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire Solva : Situation familiale',
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity.distributor.distributorNb),
        Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails?.tncRt as number) ?? undefined,
        Term: (financialDetails?.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
      });
    }
  }, []);
  const storeFamily = opportunity.person?.familySituation;
  type SituationFamilialeSchema = z.infer<typeof situationFamilialeSchema>;
  const onFormikValidate = (values: any) => {
    const result = situationFamilialeSchema.safeParse(values) as any;
    if (result.success) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };
  const onFormikSubmit = useCallback(
    (values: any) => {
      if (isActionLoading) {
        return;
      }
      const { familySituation, nbOfChildren } = values;
      const payload = {
        childrenNb: nbOfChildren || 0,
        maritalStatusCd: familySituation,
      };
      updateIsActionLoading(true);
      postData(
        'vendors/opportunities/v1/opportunity',
        {
          persons: [
            {
              personId: person.personId,
              familySituation: payload,
            },
          ],
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: SITUATIONFAMILIALE,
              loaded: true,
              actionPageDone: true,
              disabled: false,
            })
          );
          dispatch(setFamilySituation(payload));
          updateIsActionLoading(false);
          goToNextStep();
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: SITUATIONFAMILIALE,
              loaded: true,
              actionPageDone: true,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateIsActionLoading(false);
          navigate(ERRORPAGE);
        }
      );
    },
    [isActionLoading]
  );
  const formik = useFormik<Partial<SituationFamilialeSchema>>({
    initialValues: {
      familySituation: storeFamily?.maritalStatusCd,
      nbOfChildren:
        typeof storeFamily?.childrenNb === 'number'
          ? storeFamily.childrenNb
          : undefined,
    },
    validateOnBlur,
    validateOnChange,
    validate: onFormikValidate,
    onSubmit: onFormikSubmit,
  });
  const [isNbOfChildrenChanged, setIsNbOfChildrenChanged] = useState(false);
  const onNbOfChildrenChanged = (e: Event) => {
    setIsNbOfChildrenChanged(true);
    formik.setTouched(
      { ...formik.touched, nbOfChildren: true },
      validateOnBlur
    );
    formik.handleChange(e);
  };
  const [isNbOfChildrenFocused, setIsNbOfChildrenFocused] = useState(false);
  const onNbOfChildrenFocused = () => {
    setIsNbOfChildrenFocused(true);
  };
  const onNbOfChildrenBlur = () => {
    formik.setTouched(
      { ...formik.touched, nbOfChildren: true },
      validateOnBlur
    );
  };
  const isFamilySituationError = useMemo(
    () =>
      formik.touched.familySituation &&
      typeof formik.errors.familySituation === 'string',
    [formik.touched.familySituation, formik.errors.familySituation]
  );
  const isNbOfChildrenError = useMemo(() => {
    return (
      formik.touched.nbOfChildren &&
      typeof formik.errors.nbOfChildren === 'string'
    );
  }, [formik.touched.nbOfChildren, formik.errors.nbOfChildren]);

  const isFormValid = useMemo(
    () =>
      ((storeFamily?.childrenNb !== undefined &&
        storeFamily?.maritalStatusCd !== undefined) ||
        (Object.values(formik.touched).every((v) => v === true) &&
          Object.values(formik.touched).length > 0)) &&
      Object.values(formik.errors).length <= 0,
    [storeFamily, formik.touched, formik.errors]
  );
  return {
    formik,
    isFormValid,
    isActionLoading,
    isNbOfChildrenFocused,
    onNbOfChildrenBlur,
    isNbOfChildrenError,
    isFamilySituationError,
    isNbOfChildrenChanged,
    nbOfChildErrorMessageContent: isNbOfChildrenError
      ? formik.errors.nbOfChildren
      : undefined,
    goBackToStep,
    onSubmit: formik.handleSubmit,
    onNbOfChildrenFocused,
    onNbOfChildrenChanged,
  };
};

export default useSituationFamilialeForm;
