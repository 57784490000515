import ReactDOM from 'react-dom/client';
import 'sanitize.css/sanitize.css';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { App } from '@vat/layouts';
import { StoreProvider } from '@sweb-front/components';
import store from '@sweb-front/store';
import { EngineContextProvider } from '@vat/utils';
import { FONTLOADED } from './configuration';
import { jwtDecode } from 'jwt-decode';
import { t } from 'i18next';
import { TToken } from './types';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const path = new URLSearchParams(window.location.search);
const token = path.has('token')
  ? path.get('token')
  : localStorage.getItem('token');

let decodedToken: TToken;
try {
  decodedToken = jwtDecode(token) as TToken;
} catch {
  decodedToken = { vendorId: '100' } as TToken;
}
const isCafineoVendor = ['240'].includes(decodedToken.vendorId.substring(0, 3));
// Displays loader before app render
root.render(
  <div className="loader-wrapper">
    <div className={`loader ${isCafineoVendor ? 'blue' : 'green'}`}></div>
    <div className={`label-loader`}>{t('common.loading')}</div>
  </div>
);

document
  .getElementById('prepare-env')
  .addEventListener('prepare-env-loaded', () => {
    // First load all the font
    document.fonts.ready.then(() => {
      if (localStorage.getItem(FONTLOADED) === 'loaded') {
        root.render(
          <BrowserRouter>
            <StoreProvider store={store}>
              <EngineContextProvider>
                <App />
              </EngineContextProvider>
            </StoreProvider>
          </BrowserRouter>
        );
      }
    });
  });
