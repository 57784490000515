import { BaseButton } from '@sweb-front/components';
import { Trans, useTranslation } from 'react-i18next';
import { selectNavigation, useAppSelector } from '@sweb-front/store';
import {
  ContentWrapper,
  DocumentInPersonalAccountParagraph,
  DocumentInPersonalAccountParagraphExplanation,
  ImgWrapper,
  SectionWrapper,
  AcceptedLoan,
  TitleCmp,
  CustomizedValidateButtonWrapper,
  DocumentReceived,
  AsteriskWrapper,
  CustomedBubbleInfoCmp,
} from './styles';
import useApprovedPayment from './useApprovedPayment';
import { getDataBySetting, useEngineLoader } from '@vat/utils';
import config from 'src/utils/hooks/engineContext';

const ApprovedPayment = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);
  const companyCd = useEngineLoader();
  const companyDataLogo = getDataBySetting(config, companyCd).value.response
    .logo;
  const { onContractClick } = useApprovedPayment(
    opportunity,
    parameters,
    navigation
  );
  const { wayType } = parameters;

  return (
    <SectionWrapper>
      <ImgWrapper>
        <img
          width={companyDataLogo.width}
          height={companyDataLogo.height}
          src={companyDataLogo.src}
          style={companyDataLogo?.style}
          alt={t('payment_success')}
        />
      </ImgWrapper>
      <ContentWrapper>
        <TitleCmp level={3}>{t('responses.accepted.congratulation')}</TitleCmp>
        <AcceptedLoan>{t('responses.accepted.definitely')}</AcceptedLoan>
        <CustomedBubbleInfoCmp
          hasIcon={true}
          hasBorder={false}
          icon="information-lamp"
          descriptionCls="blue-color"
          description={
            <Trans
              i18nKey="responses.accepted.downloadDocuments"
              components={{
                contractspace: (
                  <span>{t('responses.accepted.downloadDocumentsBold')}</span>
                ),
              }}
            />
          }
        />
        <DocumentReceived>
          <Trans
            i18nKey="responses.accepted.documentReceived"
            components={{
              contractspace: (
                <span>{t('responses.accepted.downloadDocumentsBold')}</span>
              ),
            }}
          />
        </DocumentReceived>
        {parameters.showContractBtn && (
          <CustomizedValidateButtonWrapper>
            <BaseButton
              onClick={onContractClick}
              id="contract-download-button"
              secondary
            >
              {t('responses.buttons.contractArea')}
            </BaseButton>
          </CustomizedValidateButtonWrapper>
        )}

        <DocumentInPersonalAccountParagraph>
          {t('responses.accepted.documentInPersonalAccount')}
        </DocumentInPersonalAccountParagraph>
        {wayType && (
          <DocumentInPersonalAccountParagraphExplanation>
            {t(`responses.accepted.${wayType}.getContractCopy`)}
          </DocumentInPersonalAccountParagraphExplanation>
        )}

        <AsteriskWrapper>
          <Trans
            i18nKey="responses.accepted.asterix"
            components={{
              website: <a href="https://www.cetelem.fr" target="_blank"></a>,
            }}
          />
        </AsteriskWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default ApprovedPayment;
