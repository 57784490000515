import { isStringEmpty } from '@sweb-front/utils';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { GenderInput } from '../DesignSystem';
import Modal from '../Modal/Modal';

import {
  SelectInputWrapper,
  TitleWrapper,
  ChevronWrapper,
  SVGIconWrapper,
  IconWrapper,
  LabelWrapper,
  ErrorMessageWrapper,
  ModalContentWrapper,
} from './styles';
import CustomedBubbleInfo from '../CustomedBubbleInfo/CustomedBubbleInfo';

export interface IListItem {
  value: unknown;
  displayValue: unknown;
}

export interface IModalSelectProps {
  id?: string;
  label?: string;
  list: IListItem[];
  value?: unknown;
  isClosable?: boolean;
  errorMessage?: string;
  onChange?: (e: Event, item?: IListItem) => void;
  required?: boolean;
  invalid?: boolean | null;
  placeholder?: string;
  selectionTitle?: string;
  showErrorMessage?: boolean;
  bubbleInfoDescription?: string;
}

export const ModalSelect = ({
  id,
  list,
  value,
  label,
  isClosable = true,
  errorMessage,
  onChange,
  invalid = null,
  required,
  placeholder,
  selectionTitle,
  showErrorMessage,
  bubbleInfoDescription,
}: IModalSelectProps) => {
  const selectedValue = (list ?? []).find((it) => it.value === (value ?? ''));
  const [isOpen, setIsOpen] = useState(false);
  const [val, setVal] = useState<string>();
  const [validity, setValidity] = useState<boolean | null>(invalid);
  const [isMounted, setIsMounted] = useState(false);
  const [isReadyToCatchEvent, setIsReadyToCatchEvent] = useState(false);

  const [icon, setIcon] = useState<ReactNode>();

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (invalid == null) {
      return;
    }
    setValidity(!invalid);
  }, [invalid]);

  useEffect(() => {
    if (invalid === null) {
      setIcon(undefined);
    } else if (!invalid || validity === true) {
      setIcon(
        <SVGIconWrapper validity>
          <base-icon icon="check-circle-fill" />
        </SVGIconWrapper>
      );
    } else if (invalid || validity === false) {
      setIcon(
        <SVGIconWrapper validity={false}>
          <base-icon icon="info-fill" />
        </SVGIconWrapper>
      );
    }
  }, [invalid, validity, value]);

  const onCloseModal = useCallback(
    (e) => {
      if (required) {
        setValidity(!isStringEmpty(selectedValue?.value as string));
      }
      onChange?.(
        {
          target: {
            id: `ms_modal_${id}`,
          },
        } as never,
        selectedValue
      );
      setIsOpen(false);
    },
    [required, selectedValue, invalid]
  );

  return (
    <>
      <LabelWrapper id={id} onClick={() => setIsOpen((prev) => !prev)}>
        {label}
      </LabelWrapper>
      <ChevronWrapper
        id={id}
        invalid={invalid}
        validity={validity}
        onClick={() => setIsOpen((prev) => !prev)}
        role="option"
      >
        <SelectInputWrapper>
          {!selectedValue ? (
            <span className="placeholder">{placeholder}</span>
          ) : (
            <span>{selectedValue?.displayValue as string}</span>
          )}
        </SelectInputWrapper>
        <IconWrapper>
          {icon}
          <SVGIconWrapper invalid={invalid} validity={validity}>
            <base-icon icon="chevron-right" />
          </SVGIconWrapper>
        </IconWrapper>
      </ChevronWrapper>

      {required &&
        showErrorMessage &&
        (validity === false || invalid === true) && (
          <ErrorMessageWrapper>
            {errorMessage ?? 'Veuillez choisir une option'}
          </ErrorMessageWrapper>
        )}
      <Modal
        isVisible={isOpen}
        handleCloseModal={onCloseModal}
        isClosable={isClosable}
      >
        <ModalContentWrapper>
          <TitleWrapper level={1}>{selectionTitle}</TitleWrapper>
          {!isStringEmpty(bubbleInfoDescription) && (
            <CustomedBubbleInfo
              id={`modal-select-${id}`}
              description={bubbleInfoDescription}
            />
          )}
          {list?.length ? (
            <GenderInput
              id={`ms_modal_${id}`}
              value={selectedValue?.value ?? ''}
              onSelectedElementChange={(e: Event) => {
                const selected = (e as any).target.value;
                const [selectedItem] = (list ?? ([] as IListItem[])).filter(
                  (it) => it.value === selected
                );
                // for any reason, this code is called as soon as dom is loaded due to Desgin system component
                if (
                  !isReadyToCatchEvent &&
                  invalid === null &&
                  validity === null &&
                  !isStringEmpty(value as string)
                ) {
                  setVal(selectedItem.displayValue as any);
                  setIsReadyToCatchEvent(true);
                  setIsOpen(false);
                  return;
                }

                if (isMounted) {
                  if (required) {
                    setValidity(!isStringEmpty(val));
                  }
                  setVal(selectedItem.displayValue as any);
                  setValidity(true);
                  onChange?.(e, selectedItem);
                  setIsOpen(false);
                }
              }}
              hasFocus
            >
              {list?.map(({ value: item, displayValue }, index) => {
                const isSelected = displayValue === selectedValue?.displayValue;
                return (
                  <selected-button
                    key={`ms_item_${id}_${index + 1}`}
                    value={item}
                    secondary="true"
                    selected={isSelected === true ? true : null}
                  >
                    {displayValue}
                  </selected-button>
                );
              })}
            </GenderInput>
          ) : (
            <span>La liste de sélection est vide.</span>
          )}
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default ModalSelect;
