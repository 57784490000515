import { HelperMessageWrapper } from '@sweb-front/styles';
import styled from 'styled-components';
import LeaveActionLink from '../LeaveActionLink/LeaveActionLink';
import { TAB } from '@sweb-front/utils';

export const LeaveActionLinkWrapper = styled(LeaveActionLink)`
  &.leaveaction-wrapper {
    width: inherit;
    align-self: flex-start;

    @media screen and (min-width: 400px) {
      align-self: flex-end;
    }

    & .edit-info-wbm {
      padding-left: 0.4rem;
      font-family: 'Open Sans Bold';
      text-decoration: none;
      color: var(--neutrals-700);
      font-size: 14px;
      line-height: 20px;
      margin-top: 0.8rem;
      margin-bottom: 1.2rem;
    }

    & base-icon {
      --color-icon: var(--neutrals-700);
      margin-top: -0.6rem;

      @media screen and ${TAB} {
        margin-top: -0.5rem;
      }
    }
  }
`;

export const CustomerInformationnCardWrapper = styled(HelperMessageWrapper)`
  display: flex;
  justify-content: left;
  position: relative;
  background: #fef4e5;
  color: #292c2e;
  border: 1px solid var(--color-border-accent-orange, #e78a08);

  & .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 1.2rem;
  }
`;

export const ImgWrapper = styled.div`
  padding: 0;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
    --color-icon: #e78a08;
  }
`;

export const TitleWrapper = styled.div`
  font-family: Open Sans Bold;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.6rem;
  text-align: left;
`;

export const MessageWrapper = styled.div`
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: #4b4f54;
  padding-left: 2.4rem;
`;

export const InfoWrapper = styled.div`
  padding-left: 2.4rem;
  & div {
    font-family: Open Sans Bold;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.01em;
    text-align: left;
    color: #4b4f54;
    word-break: break-word;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.4rem;
  align-self: end;
`;

export const StyledButton = styled.button`
  width: 10.4rem;
  height: 3.6rem;
  padding: 0.8rem 0.4rem 0.8rem 0.8rem;
  border-radius: 0.4rem;
  background: transparent;
  border: 1px solid black;
  font-family: Open Sans Bold;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`;
