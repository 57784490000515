import lazyRetry from './utils/lazyRetry';

// Lists all available steps of our application (only pages that are returned by the parameters)
export enum PAGE {
  KYC = 'KYC',
  SOLVA = 'SOLVA',
  SOLVAL = 'SOLVAL',
  SITFAM = 'SITFAM',
  LOGE = 'LOGE',
  PROF = 'PROF',
  ASSU = 'ASSU',
  CB = 'CB',
  UPPJ = 'UPPJ',
  ESIGN = 'ESIGN',
  CHOIXOB = 'CHOIXOB', // choix open banking RIB
  CHOIXOBCB = 'CHOIXOBCB', // choix open banking CB
  OBI = 'OBI',
  LIV = 'LIV',
}

// Lists the components name for each page
export enum PAGECMP {
  INFORMATION = 'Information',
  INCOMESEXPENSES = 'IncomesExpenses',
  OPTIONS = 'Options',
  CARDINFORMATION = 'CardInformation',
  SUPPORTIVEINFORMATION = 'SupportiveInformation',
  ESIGNATURE = 'ESignature',
  ACCORDPRET = 'ApprovedPayment',
  SITUATIONFAMILIALE = 'SituationFamiliale',
  PROFESSIONALSITUATION = 'ProfessionalSituation',
  UPPJ = 'InitOnbo',
  HOUSING = 'Housing',
  SOLVAL = 'IncomesExpensesLong',
  CHOIXOPENBAKNING = 'OpenBankingChoice',
  CHOIXOPENBAKNINGCB = 'OpenBankingChoiceCb',
  LIVENESS = 'Liveness',
  OBI = 'OBI',
}

// PAGES DISPONIBLE DANS LE PARCOURS
export const INFORMATIONS = '/informations';
export const OPENBANKINGCHOICE = '/choix-open-banking';
export const SOLVA = '/revenus-charges';
export const SITUATIONFAMILIALE = '/situation-familiale';
export const LOGEMENT = '/logement';
export const SITUATIONPROF = '/situations-professionnelles';
export const SOLVALONG = '/revenus-charges-long';
export const INSURANCE = '/options';
export const INFORMATIONBANCAIRES = '/informations-bancaires';
export const PIECESJUSTIFICATIVES = '/pieces-justificatives';
export const LIVENESS = '/liveness';
export const OPENBANKING = '/open-banking';
export const ESIGNATURE = '/e-signatures';
export const REDIRECTION = '/redirection';

// PAGES FICTIVES DE DEBRANCHEMENT
export const THREEDS = '/3ds';
export const DOAUTHORIZATION = '/do-authorization';

// PAGE APPELEE MAIS PAS DANS LES ETAPES
export const OBCONNEXIONERROR = '/ob-connexion-error';

export interface IPageDetails {
  url: string;
  key_label: string;
  components: React.ReactNode;
  isSubStep?: boolean;
}

export interface IPageProperty {
  [s: string]: IPageDetails;
}

/**
 * Gérer la réponse du paramétrage pour se conformer à la configuration
 * cas spécifique pour SOLVA
 * @param name
 * @return {string} le nom du page
 */
export const computePageName = (name: string): string => {
  const regExp = new RegExp('^SOLVA[0-9]{1,}$');
  let page = name;
  if (regExp.test(name)) {
    page = name.replace(regExp, 'SOLVA');
  }
  return page;
};

// Builds for each page its URL, its title and its components from the data we gather behind
export const URL_MAPPING: IPageProperty = {
  [PAGE.KYC]: {
    url: INFORMATIONS,
    key_label: 'basicInformations',
    components: PAGECMP.INFORMATION,
  },
  [PAGE.SOLVA]: {
    url: SOLVA,
    key_label: 'incomesAndExpenses',
    isSubStep: true,
    components: PAGECMP.INCOMESEXPENSES,
  },
  [PAGE.ASSU]: {
    url: INSURANCE,
    key_label: 'insurance',
    components: PAGECMP.OPTIONS,
  },
  [PAGE.CB]: {
    url: INFORMATIONBANCAIRES,
    key_label: 'cardInformation',
    components: PAGECMP.CARDINFORMATION,
  },
  [PAGE.UPPJ]: {
    url: PIECESJUSTIFICATIVES,
    key_label: 'supportiveInformation',
    components: PAGECMP.UPPJ,
  },
  [PAGE.ESIGN]: {
    url: ESIGNATURE,
    key_label: 'esignature',
    components: PAGECMP.ESIGNATURE,
  },
  [PAGE.PROF]: {
    url: SITUATIONPROF,
    key_label: 'professionalSituation',
    isSubStep: true,
    components: PAGECMP.PROFESSIONALSITUATION,
  },
  [PAGE.SOLVAL]: {
    url: SOLVALONG,
    key_label: 'incomeAndExpensesLong',
    isSubStep: true,
    components: PAGECMP.SOLVAL,
  },
  [PAGE.SITFAM]: {
    url: SITUATIONFAMILIALE,
    key_label: 'situationFamiliale',
    isSubStep: true,
    components: PAGECMP.SITUATIONFAMILIALE,
  },
  [PAGE.LOGE]: {
    url: LOGEMENT,
    key_label: 'logement',
    isSubStep: true,
    components: PAGECMP.HOUSING,
  },
  [PAGE.CHOIXOB]: {
    url: OPENBANKINGCHOICE,
    key_label: 'openBanking',
    isSubStep: true,
    components: PAGECMP.CHOIXOPENBAKNING,
  },
  [PAGE.CHOIXOBCB]: {
    url: OPENBANKINGCHOICE,
    key_label: 'openBanking',
    isSubStep: true,
    components: PAGECMP.CHOIXOPENBAKNINGCB,
  },
  [PAGE.LIV]: {
    url: LIVENESS,
    key_label: 'liveness',
    isSubStep: true,
    components: PAGECMP.LIVENESS,
  },
  [PAGE.OBI]: {
    url: OPENBANKING,
    key_label: 'obPage',
    isSubStep: true,
    components: PAGECMP.OBI,
  },
};

const Information = lazyRetry(() => import('./layouts/Information'));
const IncomeExpenses = lazyRetry(() => import('./layouts/IncomesExpenses'));
const Options = lazyRetry(() => import('./layouts/Options'));
const CardInformation = lazyRetry(() => import('./layouts/CardInformation'));
const SupportiveInformation = lazyRetry(
  () => import('./layouts/SupportiveInformation')
);
const ESignature = lazyRetry(() => import('./layouts/ESignature'));
const ApprovedPayment = lazyRetry(() => import('./layouts/ApprovedPayment'));
const PROFESSIONALSITUATION = lazyRetry(
  () => import('./layouts/ProfessionalSituation')
);
const InitOnbo = lazyRetry(() => import('./layouts/InitOnbo'));

const SituationFamiliale = lazyRetry(
  () => import('./layouts/SituationFamiliale')
);
const HousingComponent = lazyRetry(() => import('./layouts/Housing'));
const IncomeExpensesLong = lazyRetry(
  () => import('./layouts/IncomesExpensesLong')
);
const OpenBankingChoiceComponent = lazyRetry(
  () => import('./layouts/OpenBankingChoice')
);
const LivenessComponent = lazyRetry(() => import('./layouts/Liveness'));
const OpenBanking = lazyRetry(() => import('./layouts/OpenBankingTink'));

// Return the matching components within its page
export const getMatchingComponents = (type?: PAGECMP) => {
  switch (type) {
    case PAGECMP.PROFESSIONALSITUATION:
      return <PROFESSIONALSITUATION />;
    case PAGECMP.INFORMATION:
      return <Information />;
    case PAGECMP.INCOMESEXPENSES:
      return <IncomeExpenses />;
    case PAGECMP.OPTIONS:
      return <Options />;
    case PAGECMP.CARDINFORMATION:
      return <CardInformation />;
    case PAGECMP.SUPPORTIVEINFORMATION:
      return <SupportiveInformation />;
    case PAGECMP.UPPJ:
      return <InitOnbo />;
    case PAGECMP.ESIGNATURE:
      return <ESignature />;
    case PAGECMP.HOUSING:
      return <HousingComponent />;
    case PAGECMP.SITUATIONFAMILIALE:
      return <SituationFamiliale />;
    case PAGECMP.SOLVAL:
      return <IncomeExpensesLong />;
    case PAGECMP.ACCORDPRET:
      return <ApprovedPayment />;
    case PAGECMP.CHOIXOPENBAKNING:
      return <OpenBankingChoiceComponent isVATRib={true} />;
    case PAGECMP.CHOIXOPENBAKNINGCB:
      return <OpenBankingChoiceComponent isVATRib={false} />;
    case PAGECMP.LIVENESS:
      return <LivenessComponent />;
    case PAGECMP.OBI:
      return <OpenBanking />;
    default: {
      return <Information />;
    }
  }
};

export enum INFORMATIONPAGE {
  KYCBLOC1 = 'KYCBLOC1',
  KYCNOMNAI = 'KYCNOMNAI',
  KYCDTNAI = 'KYCDTNAI',
  KYCPAYSNAI = 'KYCPAYSNAI',
  KYCDPTNAI = 'KYCDPTNAI',
  KYCVILNAI = 'KYCVILNAI',
  KYCNATIO = 'KYCNATIO',
  KYCBLOC2 = 'KYCBLOC2',
  KYCBLOC3 = 'KYCBLOC3',
  KYCBLOC4 = 'KYCBLOC4',
  SECTEUR = 'SECTEUR',
}

export enum SOLVAPAGE {
  SOLVACT = 'SOLVACT',
  SOLVREV = 'SOLVREV',
  SOLVCHRGS = 'SOLVCHRGS',
  SOLVCHRGC = 'SOLVCHRGC',
  SOLVCPLT = 'SOLVCPLT',
}

// Lists fields that can be present in each page
export const FORM_FIELDS_MAPPING = {
  KYC: {
    [INFORMATIONPAGE.KYCBLOC1]: 1, //   Civilité
    [INFORMATIONPAGE.KYCBLOC4]: 2, //   nom et prénom (éditable)
    [INFORMATIONPAGE.KYCNOMNAI]: 3, //  Nom de naissance
    [INFORMATIONPAGE.KYCDTNAI]: 4, //   Date de naissance
    [INFORMATIONPAGE.KYCPAYSNAI]: 5, // Pays de naissance
    [INFORMATIONPAGE.KYCDPTNAI]: 6, //  Département de naissance
    [INFORMATIONPAGE.KYCVILNAI]: 7, //  Ville de naissance
    [INFORMATIONPAGE.KYCNATIO]: 8, //   Nationalité
    [INFORMATIONPAGE.KYCBLOC2]: 9, //   Adresse de facturation
    [INFORMATIONPAGE.KYCBLOC3]: 10, //   Contact
    [INFORMATIONPAGE.SECTEUR]: 11, //   Secteur
  },
  SOLVA: {
    [SOLVAPAGE.SOLVACT]: 1, //    Champ secteur d'activité
    [SOLVAPAGE.SOLVREV]: 2, //    Champ revenus et charges
    [SOLVAPAGE.SOLVCHRGS]: 3, //  Champ charges agrégées
    [SOLVAPAGE.SOLVCHRGC]: 4, //  Bloc 3 charges : loyer, crédit, pensions
    [SOLVAPAGE.SOLVCPLT]: 5, //   Bloc données complémentaires
  },
};

// Other application pages
export const SUCCESSREPONSE = '/acceptee';
export const REFUSEDREPONSE = '/refusee';
export const WAITINGRESPONSE = '/demande-en-attente';
export const MENTIONSLEGALES = '/mentions-legales';
export const MENTIONSLEGALES100 = '/mentions-legales-100';
export const MENTIONSLEGALES140 = '/mentions-legales-140';
export const COOKIES = '/politique-cookies';
export const SMSMENTIONLEGALE = '/sms-mention-legale';
export const ERRORPAGE = '/erreur';
export const MAINTENANCE = '/maintenance';

// Some variables that used to set in localStorage
export const STOREDVATTYPE = 'vatType';
export const STOREDSOLVAKEY = 'solvaData';
export const SOLVARIB = 'solva-rib';
export const STOREDPRFKEY = 'prfData';
export const STOREDASSUKEY = 'optionData';
export const ERRORKEY = 'errorCode';

// Variable used to check if in url we have a certain fields that are useful
export const APPPARAMS = {
  redirectFromMonext: ['cardId', 'cardRef'],
  redirectFromSignature: [],
  redirectFrom3DS: ['threeDsRef'],
};

// Cause for now, we don't have a way to check if a vendor is orange, we have to declare it manually
export const VENDORLIST = {
  ORANGE: '1007355845',
};

export enum OPPORTUNITYSTATUS {
  ANUL = 'ANUL',
}

export const WEBAPAGE_TITLES = {
  [INFORMATIONS]: 'Formulaire KYC',
  [OPENBANKINGCHOICE]: 'Choix Open Banking',
  [SOLVA]: 'Formulaire Solva',
  [SITUATIONFAMILIALE]: 'Formulaire Solva : Situation familiale',
  [LOGEMENT]: 'Formulaire Solva : Logement',
  [SITUATIONPROF]: 'Formulaire Solva : Situation professionnelle',
  [SOLVALONG]: 'Formulaire Solva : Revenus',
  [INSURANCE]: 'Formulaire Assurance',
  [LIVENESS]: 'Liveness : Identité',
  [OBCONNEXIONERROR]: 'Open Banking : Connexion perdue',
};

// For the adobe scripts
export const ADOBELOADED = 'ADOBELOADED';
export const ADOBELOADEDVALUE = 'loaded';
export const FONTLOADED = 'fontLoaded';

export const COOKIES_CSS_ID = 'ot-sdk-cookie-policy';

export const COMPANYCD = 'companyCd';
