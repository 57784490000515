import { HelperMessageWrapper } from '@sweb-front/styles';
import styled from 'styled-components';
import { CloseIcon } from '../Icons';
import { TAB } from '@sweb-front/utils';

export const CustomedBubbleInfoWrapper = styled(HelperMessageWrapper)<{
  hasBorder?: boolean;
}>`
  display: flex;
  justify-content: left;
  vertical-align: middle;
  position: relative;
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #1068d0' : 'none')};
  background: #e5f5fd;
  color: #4b4f54;
  z-index: 20;

  @media screen and ${TAB} {
    z-index: 0;
  }

  & .bubbleinfo-description {
    flex: 1;
    word-break: break-word;
    margin-top: 0.2rem;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    line-height: 20px;

    & span {
      color: var(--neutrals-700);
    }
  }
`;

export const ImgWrapper = styled.div`
  padding: 0;
  align-self: flex-start;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2rem;
    --icon-height: 2rem;
    --color-icon: var(--blue-500);
  }
`;

export const StyledCloseIcon = styled(CloseIcon)<{ isDisabled?: boolean }>`
  color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.variables.colors.chartColors.grey_100
      : theme.variables.colors.chartColors.dark_700};
  width: 2.4rem;
  cursor: pointer;
`;
