/* eslint-disable consistent-return */
import {
  useAppDispatch,
  useAppSelector,
  setHousing,
  IOpportunityState,
  updateParcoursNavigation,
} from '@sweb-front/store';
import { useLoadingHook, useManagingStep } from '@sweb-front/hooks';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { trackEvent } from '@sweb-front/utils';
import { postData, setIdHashed } from '../utils';
import { HOUSING_FORM_VALUES } from './HousingForm';
import { ERRORPAGE, LOGEMENT } from '@vat/configuration';
import { useNavigate } from 'react-router-dom';

type HousingOptions = {
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
};

const useHousingForm = (
  opportunity: IOpportunityState,
  options: HousingOptions,
  birthDate?: string
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigate = useNavigate();

  const validateOnBlur = options?.validateOnBlur || true;
  const validateOnChange = options?.validateOnChange || true;
  const { person } = opportunity;
  const { goBackToStep, goToNextStep } = useManagingStep();
  const housingValues = HOUSING_FORM_VALUES.map(({ value }) => value);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const housingEntryDtScheam = z
    .date({
      required_error: t('housing.housingEntryDt.errors.required'),
      invalid_type_error: t('housing.housingEntryDt.errors.required'),
    })
    .min(new Date(birthDate || '1900-01-01'), {
      message: t('housing.housingEntryDt.errors.invalid'),
    })
    .max(new Date(), { message: t('housing.housingEntryDt.errors.invalid') });
  const housingSchema = z.object({
    housingStatusCd: z.enum(housingValues as any, {
      required_error: t('housing.housingStatusCd.errors.required'),
      invalid_type_error: t('housing.housingStatusCd.errors.required'),
    }),
    housingEntryDt: z
      .number({
        required_error: t('housing.housingEntryDt.errors.required'),
        invalid_type_error: t('housing.housingEntryDt.errors.required'),
      })
      .min(1900, t('housing.housingEntryDt.errors.invalid'))
      .superRefine((arg: any, ctx: any) => {
        const date = new Date(`${arg}-01-01`);
        const result = housingEntryDtScheam.safeParse(date) as any;
        if (result.success) {
          return;
        }
        result.error.issues.forEach((issue: any) => {
          ctx.addIssue({
            ...issue,
            message: t('housing.housingEntryDt.errors.invalid'),
          });
        });
      }),
  });

  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire Solva : Logement',
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity.distributor.distributorNb),
        Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails?.tncRt as number) ?? undefined,
        Term: (financialDetails?.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
      });
    }
  }, []);
  const onFormikValidate = (values: any) => {
    const result = housingSchema.safeParse(values) as any;
    if (result.success) {
      return;
    }
    const errors = result.error.errors.reduce(
      (prev: any, curr: { path: any[]; message: any }) => {
        return {
          ...prev,
          [curr.path[0]]: curr.message,
        };
      },
      {}
    );
    return errors;
  };
  const onFormikSubmit = useCallback(
    (values: any) => {
      if (isActionLoading) {
        return;
      }

      const payload = {
        housingStatusCd: values.housingStatusCd,
        housingEntryDt: `${values.housingEntryDt}-01-02`,
      };
      updateIsActionLoading(true);
      postData(
        'vendors/opportunities/v1/opportunity',
        {
          persons: [
            {
              personId: person.personId,
              housing: payload,
            },
          ],
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: LOGEMENT,
              loaded: true,
              actionPageDone: true,
              disabled: false,
            })
          );
          dispatch(setHousing(values));
          updateIsActionLoading(false);
          goToNextStep();
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: LOGEMENT,
              loaded: true,
              actionPageDone: true,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateIsActionLoading(false);
          navigate(ERRORPAGE);
        }
      );
    },
    [isActionLoading, opportunity, person]
  );

  type HousingSchema = z.infer<typeof housingSchema>;
  const storeHousing = opportunity.person?.housing;
  const formik = useFormik<Partial<HousingSchema>>({
    initialValues: {
      housingStatusCd: storeHousing?.housingStatusCd,
      housingEntryDt:
        storeHousing?.housingEntryDt &&
        Array.isArray(storeHousing?.housingEntryDt)
          ? storeHousing.housingEntryDt[0]
          : storeHousing?.housingEntryDt,
    },
    validateOnBlur,
    validateOnChange,
    validate: onFormikValidate,
    onSubmit: onFormikSubmit,
  });
  const [isHousingEntryDtChanged, setIsHousingEntryDtChanged] = useState(false);
  const [isEndTyping, setIsEndOfTyping] = useState(false);
  const onHousingEntryDtChanged = (e: Event) => {
    setIsHousingEntryDtChanged(true);
    formik.setTouched(
      { ...formik.touched, housingEntryDt: true },
      validateOnChange
    );
    if ((e.target as HTMLInputElement).value.length < 4) {
      setIsEndOfTyping(false);
    } else {
      setIsEndOfTyping(true);
    }
    formik.handleChange(e);
  };

  const onHousingEntryDtBlurred = () => {
    setIsEndOfTyping(true);
  };

  const isHousingEntryDtError = useMemo(
    () =>
      formik.touched.housingEntryDt &&
      typeof formik.errors.housingEntryDt === 'string',
    [formik.touched.housingEntryDt, formik.errors.housingEntryDt]
  );

  // eslint-disable-next-line no-nested-ternary
  const housingEntryDtErrorMsg =
    isHousingEntryDtError && isEndTyping
      ? formik.errors.housingEntryDt
      : isEndTyping
      ? undefined
      : '';

  const isHousingStatusCdError = useMemo(
    () =>
      formik.touched.housingStatusCd &&
      typeof formik.errors.housingStatusCd === 'string',
    [formik.touched.housingStatusCd, formik.errors.housingStatusCd]
  );

  const isFormValid = useMemo(
    () =>
      ((storeHousing?.housingEntryDt !== undefined &&
        storeHousing?.housingStatusCd !== undefined) ||
        (Object.values(formik.touched).every((v) => v === true) &&
          Object.values(formik.touched).length > 0)) &&
      Object.values(formik.errors).length <= 0,
    [storeHousing, formik.touched, formik.errors]
  );

  const submitButtonClass = !isFormValid ? 'isDisabled' : '';

  return {
    isHousingStatusCdError,
    housingEntryDtErrorMsg,
    formik,
    isFormValid,
    isHousingEntryDtError,
    isHousingEntryDtChanged,
    submitButtonClass,
    isActionLoading,
    goBackToStep,
    onSubmit: formik.handleSubmit,
    onHousingEntryDtChanged,
    onHousingEntryDtBlurred,
  };
};
export default useHousingForm;
