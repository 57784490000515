import React from 'react';
import { SkinType } from '@sweb-front/types';
import { WarningIcon } from '../Icons';
import Wrapper, {
  StyledWrapperWarningMessage,
  StyledWarningMessage,
  StyledAdviceIcon,
} from './styles';

export interface ITooltipProps {
  children?: React.ReactNode;
  isVisible?: boolean;
  skin?: SkinType;
  hasArrowDown?: boolean;
  className?: string;
  icon?: React.FC;
}

const Tooltip = ({
  children,
  isVisible = false,
  hasArrowDown = true,
  skin = 'advice',
  className = '',
  icon,
}: ITooltipProps): React.ReactElement => {
  const getIcon = (): React.ReactElement | null => {
    if (icon) {
      return <StyledAdviceIcon skin={skin} as={icon} />;
    }
    if (skin === 'warning') {
      return <StyledAdviceIcon skin={skin} as={WarningIcon} />;
    }
    return skin === 'advice' || skin === 'info' || skin === 'primary' ? (
      <StyledAdviceIcon skin={skin} />
    ) : null;
  };

  return (
    <Wrapper isVisible={isVisible}>
      <StyledWrapperWarningMessage
        skin={skin}
        className={className}
        hasArrowDown={hasArrowDown}
        isVisible={isVisible}
      >
        {getIcon()}
        <StyledWarningMessage>{children}</StyledWarningMessage>
      </StyledWrapperWarningMessage>
    </Wrapper>
  );
};

export default Tooltip;
