import {
  BackToWebMerchantWrapper,
  LabelWrapper,
  SVGIconWrapper,
} from './style';

export type TLeaveActionLinkProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  text: string;
  className?: string;
};

const LeaveActionLink = ({
  onClick,
  text,
  className,
}: TLeaveActionLinkProps) => {
  return (
    <BackToWebMerchantWrapper
      onClick={onClick}
      className={`leaveaction-wrapper ${className}`}
    >
      <LabelWrapper className={className}>{text}</LabelWrapper>
      <SVGIconWrapper>
        <base-icon
          className={`open-in-new ${className}`}
          icon="open-in-new"
          viewBox="0 0 24 24"
        />
      </SVGIconWrapper>
    </BackToWebMerchantWrapper>
  );
};

export default LeaveActionLink;
