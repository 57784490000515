import { axiosInstance } from '@sweb-front/services';
import { ISelectCombo } from '@sweb-front/types';
import { concantenedObjectWithComma, isStringEmpty } from '@sweb-front/utils';
import { formatNumber } from '@vat/utils';
import { AxiosResponse } from 'axios';
import { GenderInputDS } from '@sweb-front/components';
import { RefObject } from 'react';
import { sha256 } from 'js-sha256';
import { IOpportunityState } from '@sweb-front/store';
import { t } from 'i18next';

export const pad = (s: number) => {
  return s < 10 ? `0${s}` : s;
};

export const getYearErrorMessage = (
  age: number | null,
  year?: number,
  day?: number,
  month?: number
): { isValid: boolean; errorMessage: string } => {
  if (age === null) {
    return {
      isValid: false,
      errorMessage: 'basicInformations.errors.birthDt.required',
    };
  }

  let computedAge = age;
  if (age && day && year && month) {
    const currentDate = new Date();
    const computedMonth = currentDate.getMonth() - month;
    if (
      computedMonth < 0 ||
      (computedMonth === 0 && currentDate.getDate() < day)
    ) {
      computedAge -= 1;
    }
  }

  if (year && (year < 1900 || year > new Date().getFullYear())) {
    return {
      isValid: false,
      errorMessage: 'basicInformations.errors.birthDt.yearInvalid',
    };
  }

  if (computedAge && computedAge < 0) {
    return {
      isValid: false,
      errorMessage: 'basicInformations.errors.birthDt.notExist',
    };
  }

  if (computedAge === 0 || computedAge < 18) {
    return {
      isValid: false,
      errorMessage: 'basicInformations.errors.birthDt.lessThan18',
    };
  }

  if (computedAge && computedAge > 100) {
    return {
      isValid: false,
      errorMessage: 'basicInformations.errors.birthDt.incorrectDate',
    };
  }

  return {
    isValid: false,
    errorMessage: 'basicInformations.errors.birthDt.invalid',
  };
};

export const getBirthDtValue = (value: string) => {
  let isValid = true;
  const regExp = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}$');
  let newValue: string | null = null;
  let age: number | null = null;
  let year: number | undefined;
  if (
    value &&
    value !== '' &&
    value.trim().replace(/\/+/g, '/') !== '/' &&
    regExp.test(value)
  ) {
    const tabValue = value.split('/');
    newValue = `${tabValue[2]}-${tabValue[1]}-${tabValue[0]}`;
    if (!Number.isNaN(new Date(newValue).getFullYear())) {
      age = new Date().getFullYear() - parseInt(tabValue[2], 10);
      year = parseInt(tabValue[2], 10);
    } else {
      age = NaN;
    }

    if (Number.isNaN(age) || age < 18 || age > 100) {
      isValid = false;
    }
  } else {
    isValid = false;
    newValue = value.replace(/\/+/g, '').trim();
    age = value.replace(/\/+/g, '').trim() === '' ? null : NaN;
  }

  return {
    year,
    age,
    newValue,
    isValid,
  };
};

export const getMatchedItem = (
  value: string,
  propertyToMatch: string,
  items: ISelectCombo[]
): ISelectCombo | undefined => {
  if (!value || value === '' || value === null) {
    return undefined;
  }

  return (items ?? []).find(
    (item: any) =>
      item[propertyToMatch].toUpperCase() === (value ?? '').toUpperCase()
  );
};

export const isDateInvalid = (date: string | null | undefined) => {
  const regExp = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

  if (
    !date ||
    isStringEmpty(date) ||
    !regExp.test(date) ||
    Number.isNaN(new Date(date).getFullYear())
  ) {
    return true;
  }

  const dateList = date.split('-');
  const year = dateList[0] ?? '';
  const currentDate = new Date();
  let userAge = currentDate.getFullYear() - parseInt(year, 10);

  if (new Date(date).getDate() !== parseInt(dateList[2], 10)) {
    return true;
  }

  // Add here code to manage also the month and day of birth
  const month = currentDate.getMonth() - (parseInt(dateList[1], 10) - 1);
  const day = dateList[2];
  if (month < 0 || (month === 0 && currentDate.getDate() < parseInt(day, 10))) {
    userAge -= 1;
  }

  if (userAge >= 18 && userAge <= 100) {
    return false;
  }

  return true;
};

export const isComboInputInvalid = (
  value: string | null | undefined,
  isValid: boolean
) => {
  return isStringEmpty(value) || (!isStringEmpty(value) && !isValid);
};

export const scrollToFirstInvalidElement = (
  ref?: RefObject<HTMLElement | GenderInputDS>
) => {
  const firstElement = Array.from(ref?.current?.children ?? []).find(
    (element) => element.getAttribute('aria-invalid') === 'true'
  );

  if (firstElement) {
    window.scroll({
      top: window.scrollY + firstElement.getBoundingClientRect().top,
    });
  }
};

export const isAutocompleteInvalid = (
  value: string | undefined,
  record: Record<string, string>
) => {
  return (
    isStringEmpty(value) ||
    (!isStringEmpty(value) &&
      value !==
        concantenedObjectWithComma({
          localite: record?.localite,
          codePostal: record?.codePostal,
          pays: record?.pays,
        }))
  );
};

export const isInvalidInputWithPattern = (value: string | null | undefined) => {
  return (
    isInvalidTextInput(value) ||
    /[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+/.test(value)
  );
};

export const isInvalidText = (value: string | null | undefined) => {
  return /[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+/.test(value);
};

export const isInvalidTextInput = (value: string | null | undefined) => {
  return (value ?? '')?.trim().length < 2 || (value ?? '')?.trim().length > 30;
};

export const customValidityPattern = (pattern: string, errorMessage: string) =>
  JSON.stringify([
    {
      regexp: pattern,
      errorMessage,
    },
  ]);

export const fetchData = async (
  url: string,
  urlParams: Record<string, unknown>,
  callback?: (args: ISelectCombo[]) => void,
  errorCallback?: (err: any) => void
) => {
  try {
    const response = await axiosInstance().get(url, urlParams);
    callback?.(response.data);
    return response.data;
  } catch (err) {
    return errorCallback?.(err);
  }
};

export const postData = async (
  url: string,
  params: unknown,
  callback?: (res: AxiosResponse<unknown>) => void,
  errorCallback?: (res: number) => void
) => {
  try {
    const response = await axiosInstance().post(url, params);
    callback?.(response);
    return response;
  } catch (errCode) {
    return errorCallback?.(errCode as number);
  }
};

export const roundValue = (input: string) => {
  let res: string | undefined = input;
  const [entiere, decimal, error] = input.replace(' ', '').split(',');

  if (error) {
    return res;
  }
  if (decimal && parseInt(decimal, 10) < 50 && /^[0-9]{1,2}$/.test(decimal)) {
    res = formatNumber(`${parseInt(entiere, 10)}`);
  }
  if (decimal && parseInt(decimal, 10) >= 50 && /^[0-9]{1,2}$/.test(decimal)) {
    if (['99999', '99 999'].includes(entiere)) {
      res = formatNumber(entiere);
    } else {
      res = formatNumber(`${parseInt(entiere, 10) + 1}`);
    }
  }

  return res;
};

export const isInputValidToPattern = (
  input: string | null | undefined,
  pattern: string
) => {
  const regexp = new RegExp(pattern);
  return regexp.test(input ?? '');
};

export const arrayRange = (start: number, stop: number, step: number) => {
  if (start > stop || start < 0 || stop < 0) {
    return [];
  }
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );
};

export const isCityInvalidForFranceOrOther = (
  value: string | undefined | null,
  isValidCountry: boolean,
  regexpInvalid: RegExp,
  isCountryFrance: boolean | undefined
): boolean => {
  if (isCountryFrance) {
    return isComboInputInvalid(value, isValidCountry);
  }

  if (value) {
    return regexpInvalid.test(value) || value.length > 30;
  }

  return isStringEmpty(value);
};

export const getTextInputErrorMessage = (
  field: string,
  value: NonNullable<string>,
  valueToCompare: NonNullable<string>
) => {
  if (isStringEmpty(value)) {
    return t(`basicInformations.errors.${field}.required`);
  }

  if (value && value.length > 30) {
    return t(`basicInformations.errors.${field}.required`);
  }

  if (value === valueToCompare) {
    return t('basicInformations.errors.sameNameFirstName');
  }

  return (
    t(`basicInformations.errors.${field}.format`) +
    `${isStringEmpty(value) ? '.' : ` :  ${getInvalidLetters(value)}`}. ${t(
      'basicInformations.errors.fix'
    )}`
  );
};

export const getCityErrorMessage = (
  value: string | null | undefined,
  isFreeText = false
) => {
  if (isStringEmpty(value)) {
    return !isFreeText
      ? 'basicInformations.errors.birthCity.required'
      : 'basicInformations.errors.otherBirthCity.required';
  }

  if (value && value.length > 30) {
    return 'basicInformations.errors.birthCity.maxChar';
  }

  return 'basicInformations.errors.birthCity.format';
};

export const isValueZero = (value: string | null | undefined) => {
  return parseInt((value ?? '').replace(/ /gi, ''), 10) === 0;
};

export const formatToBnpString = (
  value: string | undefined,
  maxChar: number
) => {
  const regexp = new RegExp("[^a-zA-Zzàâçéèêîôùû'´ëä -]", 'gi');
  const res = (value ?? '').replace(regexp, '');
  return res.substring(0, maxChar);
};

export const setIdHashed = (opportunity: IOpportunityState) => {
  if (!opportunity || !opportunity.person) {
    return '';
  }

  const stringToHash: string = removeSpecialCharacters(
    replaceAccents(
      opportunity.person.personalInformation.firstName +
        opportunity.person.personalInformation.name +
        opportunity.opportunityIdExt
    )
  ).toLowerCase();
  return sha256(stringToHash);
};

export function replaceAccents(string: string) {
  if (string.search(/[\xC0-\xFF]/g) > -1) {
    string = string
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF0]/g, 'd')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFD\xFF]/g, 'y');
  }

  return string;
}

export function removeSpecialCharacters(string: string) {
  return string.replace(/[^0-9a-zA-Z]/g, '');
}

export const getInvalidLetters = (value: string) => {
  return Array.from(
    new Set(
      ...[
        (value ?? '')
          .split('')
          .filter((c) =>
            c.match(/[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+/)
          ),
      ]
    )
  ).join(', ');
};
