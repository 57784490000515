import { Prompt } from '@sweb-front/components';
import { useEffect } from 'react';
import { trackEvent } from '@sweb-front/utils';
import { ErrorDeskImage } from './../ErrorPage/ErrorDeskImage';
import { t } from 'i18next';
import {
  isCetelemCompany,
  useCloseOpportunity,
  useEngineLoader,
} from '@vat/utils';
import {
  setEndParcours,
  updateOpportunityStatus,
  useAppDispatch,
} from '@sweb-front/store';
import { ERRORDETAILS } from '@sweb-front/services';
import { useUrlWebMarchand } from '@sweb-front/hooks';

const GenericError = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { getUrlError } = useUrlWebMarchand();
  const { closeOpportunity } = useCloseOpportunity();
  const companyCd = useEngineLoader();

  useEffect(() => {
    dispatch(setEndParcours(true));
    trackEvent({
      event: 'module_interaction',
      pageName: `Ecommerce : Formulaire : Erreur technique: Front`,
    });

    const token = localStorage.getItem('token');
    closeOpportunity(
      token || '',
      'TECHNICAL',
      localStorage.getItem(ERRORDETAILS),
      () => {
        dispatch(updateOpportunityStatus('ANUL'));
      },
      () => {
        console.error('Trying to cancel the loan failed');
      }
    );
  }, []);

  const redirectToWeMerchant = () => {
    if (getUrlError()) {
      window.location.href = getUrlError();
    }
  };

  return (
    <>
      {isCetelemCompany(companyCd) && <ErrorDeskImage />}
      <Prompt
        title={t('errorPage.title.message')}
        text={t('errorPage.problemEncountered')}
        description={t('errorPage.tryLater')}
        tertiaryButtonLabel={t('common.backToWebMerchant')}
        onTertiaryButtonClick={redirectToWeMerchant}
      />
    </>
  );
};

export default GenericError;
