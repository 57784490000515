import { Title } from '@sweb-front/components';
import styled from 'styled-components';

export const SVGIconErrorWrapper = styled.div`
  align-self: center;
  & base-icon {
    margin-right: 0.8rem;
    --color-icon: var(--error-500);
    --icon-width: 6rem;
    --icon-height: 6rem;
  }
`;

export const LeaveContentWrapper = styled.div`
  flex: 1;
  padding: 0 2.4rem;
  & span {
    font-size: 1.6rem;
  }
`;
export const TitleWrapper = styled(Title)`
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
  font-size: 1.8rem;
  color: var(--neutrals-700);
  line-height: 2.6rem;
`;

export const DescriptionWrapper = styled.div`
  white-space: pre-line;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--neutrals-500);
  line-height: 2.4rem;
  margin-bottom: 1.8rem;
  & .strong {
    font-family: 'Open Sans Bold';
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: block;
  }
`;

export const ButtonModalWrapper = styled.div`
  display: flex;
  margin: 1.6rem 2rem 0rem 2rem;
  flex-direction: column-reverse;
  gap: 1.2rem;

  & button {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 2rem 0rem;
    height: 3.6rem;
    width: auto;
    &:first-of-type {
      color: ${({ theme }) => theme.vendor.colors.primary};
      background: transparent;
      box-shadow: none;
    }
`;

export const ButtonLeaveModalWrapper = styled(ButtonModalWrapper)``;

export const SVGIconReturnWrapper = styled.div`
  align-self: center;
  & base-icon {
    margin-right: 1rem;
    --color-icon: var(--neutrals-0);
  }
`;
