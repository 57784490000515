import Centering from '../Centering';
import Section from '../Section';
import StyledMessage from './styles';

export interface IErrorMessageProps {
  children?: React.ReactNode;
  className?: string;
}

const ErrorMessage = ({
  children,
  className = '',
}: IErrorMessageProps): React.ReactElement => (
  <Centering>
    <Section>
      <StyledMessage className={className}>{children}</StyledMessage>
    </Section>
  </Centering>
);

export default ErrorMessage;
