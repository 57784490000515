import { Section, Title, BasicLoader } from '@sweb-front/components';
import { DESKTOP, TAB } from '@sweb-front/utils';

import styled from 'styled-components';

export const SectionWrapper = styled(Section)`
  padding-top: 1.6rem;
`;

export const TitleWrapper = styled(Title)`
  color: var(--neutrals-700);
  margin: 0 0 1.6rem 1.6rem;
  font-family: 'Ubuntu Bold';
  font-size: 2.4rem;
  line-height: 3.2rem;
  @media screen and ${DESKTOP} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const InsuranceOfferCardWrapper = styled.div`
  border-radius: 0.8rem 0.8rem;
  padding: 0.8rem 1.6rem;
  margin: 0;
`;

export const TitleBoldWrapper = styled(TitleWrapper)`
  font-family: 'Open Sans Bold';
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  color: #292c2e;
`;

export const PriceBadge = styled.span<{ isRecommended?: boolean }>`
  border-radius: 0.4rem;
  background-color: var(--teal-100);
  margin: 0.5rem 0;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  font-family: 'Open Sans Bold';
`;

export const RowElementWrapper = styled.div`
  display: flex;
  margin-top: 2.4rem;
`;

export const OptionDocumentWrapper = styled.div`
  display: flex;
  margin-top: 1.2rem;

  & base-icon {
    margin-left: 0.4rem;
    margin-top: 0.4rem;
    --color-icon: ${({ theme }) => theme.vendor.colors.primary};
    width: 1.7rem;
    height: 1.7rem;
  }
`;

export const BaseCardWrapper = styled.div`
  & base-card {
    margin-top: 0.8rem;
  }
`;

export const Fill = styled.div`
  flex-grow: 1;
`;

export const RowItem = styled.div`
  font-family: 'Open Sans';
  font-size: 1.4rem;
  color: var(--neutrals-500);
`;

export const RowItemLabel = styled(RowItem)`
  width: 13rem;
`;

export const LongRowItemLabel = styled(RowItemLabel)`
  margin-top: -1rem;
`;

export const SelectButtonWrapper = styled.div`
  margin-top: 2.4rem;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & base-button {
    max-width: 34rem;
    padding: 1.4rem 2.6rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
    background: ${({ theme }) => theme.vendor.colors.primary};
    border: ${({ theme }) => theme.vendor.colors.primary};

    &:hover {
      background: ${({ theme }) => theme.vendor.colors.secondary};
    }
  }

  @media-screen AND ${TAB} {
    min-width: 32rem;
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.6rem;
  padding: 0 1.6rem;
  & button-link {
    --color-button: ${({ theme }) => theme.vendor.colors.primary};
    font-family: 'Open Sans Bold';
    font-size: 1.6rem;
    &:hover {
      --color-button: ${({ theme }) => theme.vendor.colors.secondary};
    }
  }
`;

export const WarrantiesWrapper = styled.div`
  margin-top: 4.4rem;
  padding: 0 1.6rem;
  font-family: 'Open Sans';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--neutrals-500);
  & span {
    display: block;
  }
  & p {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }
  & p:first-of-type {
    margin-top: 0;
  }
`;

export const StyledLoader = styled(BasicLoader)`
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const FileDownloadWrapper = styled.a<{
  isLoading: boolean;
  name?: string;
}>`
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.vendor.colors.primary};
  text-decoration: underline;
  font-size: 1.4rem;
  display: inherit;
`;
