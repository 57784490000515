import React from 'react';
import { useTranslation } from 'react-i18next';
import EditButton from '../EditButton';
import CardItemContent, {
  ICardItemContentProps,
} from './items/CardItemContent';
import {
  CardItemWrapper,
  CardStyle,
  ItemsWrapper,
  TitleWrapper,
  EditWrapper,
  BannerTitleWrapper,
} from './styles';

export type TCardItem = ICardItemContentProps;

export interface ICardProps {
  cardTitle?: React.ReactNode;
  editBtnLabel?: string;
  onButtonClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  link?: string;
  readOnly?: boolean;
  cardItems: TCardItem[];
  hasEditBtn?: boolean;
}

const Card = ({
  cardTitle,
  editBtnLabel,
  cardItems,
  hasEditBtn = true,
  readOnly,
  link,
  onButtonClick,
}: ICardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <base-card style={CardStyle}>
        <BannerTitleWrapper>
          {cardTitle && (
            <TitleWrapper>{cardTitle && <span>{cardTitle}</span>}</TitleWrapper>
          )}
          {hasEditBtn && !readOnly && (
            <EditWrapper>
              {link ? (
                <EditButton href={link ?? undefined}>
                  {editBtnLabel ?? t('common.edit')}
                </EditButton>
              ) : (
                <EditButton onButtonClick={onButtonClick}>
                  {editBtnLabel ?? t('common.edit')}
                </EditButton>
              )}
            </EditWrapper>
          )}
        </BannerTitleWrapper>
        <ItemsWrapper>
          {cardItems.map(({ title, description }: TCardItem, index: number) => (
            <CardItemWrapper key={`card-item-${index + 1}`}>
              <base-card-row>
                <CardItemContent title={title} description={description} />
              </base-card-row>
            </CardItemWrapper>
          ))}
        </ItemsWrapper>
      </base-card>
    </>
  );
};

export default Card;
