import { DESKTOP, TAB } from '@sweb-front/utils';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterSeparator = styled.div`
  height: 1px;
  background: var(--neutrals-100);
  margin-top: 3.2rem;

  @media screen and ${TAB} {
    margin-top: 0;
  }
`;

export const FooterContainer = styled.footer`
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 14px;
  color: #61676c;
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;

  @media screen and ${DESKTOP} {
    padding: 0 1.6rem;
  }
`;

export const FooterStyledSpan = styled.span`
  color: #61676c;
  font-family: 'Open Sans Bold';
`;

export const FooterStyledLink = styled(Link)`
  text-decoration: underline;
  color: #61676c;
`;

export const FooterStyledNativeLink = styled.a`
  text-decoration: underline;
  color: #61676c;
`;

export const FooterStyledParagraph = styled.p`
  font-family: 'Open Sans';
  text-justify: inter-word;
  margin: 0;
  color: var(--neutrals-500);
`;

export const CookieWrapper = styled.div`
  display: inline-block;
  color: #61676c;
  text-decoration: underline;
  cursor: pointer;
`;
