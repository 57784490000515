import styled from 'styled-components';

export const WrapperLoader = styled.div<{
  $isTextInline: boolean;
}>`
  position: relative;
  & .loader.with-theme {
    font-family: sans-serif;
    font-weight: normal;
    border-bottom-color: ${({ theme }) => theme.vendor.colors.primary};
  }

  & .label-loader.with-theme {
    font-size: 2.25rem;
    margin-left: 14px;
  }
`;

export const Background = styled.div<{
  $isLoading: boolean;
  $isTextInline: boolean;
  $isHideBackground: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: row;
  background-color: inherit;
  opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0')};
  transition: opacity 0.2s;
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'auto' : 'none')};
`;
