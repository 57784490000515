import { TToken } from '@vat/types';
import { jwtDecode } from 'jwt-decode';
import { createContext, ReactNode, useContext, useMemo } from 'react';

export interface IContextEngineItem {
  company: string;
  footer: Record<string, unknown>;
  insurance: Record<string, unknown>;
  legalNotices: Record<string, unknown>;
  logo: {
    src: string;
    width: string;
    style?: Record<string, unknown>;
  };
  response: {
    logo: {
      src: string;
      width: string;
      height: string;
      style?: Record<string, unknown>;
    };
  };
}

export interface IContextEngine {
  value: IContextEngineItem;
  setting: string[];
  theme: 'cetelem' | 'cafineo';
}

export interface IContextActionProps {
  children: ReactNode;
}

export interface IEngineContextConfig {
  data: IContextEngine[];
}

export const EngineContext = createContext<string>(undefined);

export const getDataBySetting = (
  configuration: IEngineContextConfig,
  setting = '100'
) => {
  const data = configuration.data.find((item) =>
    item.setting.includes(setting)
  );
  // If the company does not exist, we set by default to 140
  // Normally it should not happened, but for protection
  if (!data) {
    return configuration.data.find((item) => item.setting.includes('100'));
  }
  return data;
};

export const EngineContextProvider = ({ children }: IContextActionProps) => {
  const url = new URLSearchParams(window.location.search);
  const token = useMemo(
    () => url.get('token') ?? localStorage.getItem('token'),
    []
  );

  let companyCd = undefined;
  try {
    companyCd = jwtDecode(token ?? '') as TToken;
  } catch {
    // For now, put 100 for default
    companyCd = { vendorId: '100' };
  }

  return (
    <EngineContext.Provider value={companyCd.vendorId.substring(0, 3)}>
      {children}
    </EngineContext.Provider>
  );
};

export const isCetelemCompany = (companyCd: string): boolean => {
  return ['100', '140'].includes(companyCd);
};

export const useEngineLoader = () => {
  const context = useContext(EngineContext);
  if (!context) {
    throw new Error(
      'EngineContext must be used within a EngineContext Provider'
    );
  }

  return context;
};
