import { ReactElement } from 'react';

interface ICheckIdentityProps {
  color: string;
}

export default function CheckIdentity({
  color,
}: ICheckIdentityProps): ReactElement {
  return (
    <>
      <svg
        width="130"
        height="130"
        viewBox="0 0 130 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M55.7172 119.58C24.9438 119.58 0 95.9969 0 65.2438C0 34.4906 24.9438 10.9078 55.7172 10.9078H74.2828C105.056 10.9078 130 34.4703 130 65.2438C130 96.0172 105.056 119.58 74.2828 119.58H55.7172Z"
          fill={color}
        />
        <path
          d="M68.4738 65.6906C72.4754 64.8375 75.4613 61.3031 75.4613 57.0578C75.4613 52.1828 71.5004 48.2219 66.6254 48.2219H63.3551C58.4801 48.2219 54.5191 52.1828 54.5191 57.0578C54.5191 61.3031 57.5254 64.8578 61.5066 65.6906C55.5957 67.2344 51.2285 72.5766 51.2285 78.975V79.625C51.2285 81.0672 52.4066 82.2453 53.8488 82.2453H76.091C77.5332 82.2453 78.7113 81.0672 78.7113 79.625V78.975C78.7113 72.5766 74.3441 67.2344 68.4535 65.6906"
          fill="white"
        />
        <path
          d="M36.5625 46.9625C35.4453 46.9625 34.5312 46.0485 34.5312 44.9313V36.8063C34.5312 35.6891 35.4453 34.775 36.5625 34.775H44.6875C45.8047 34.775 46.7188 35.6891 46.7188 36.8063C46.7188 37.9235 45.8047 38.8375 44.6875 38.8375H38.5938V44.9313C38.5938 46.0485 37.6797 46.9625 36.5625 46.9625Z"
          fill="white"
        />
        <path
          d="M93.4375 46.9625C92.3203 46.9625 91.4062 46.0485 91.4062 44.9313V38.8375H85.3125C84.1953 38.8375 83.2812 37.9235 83.2812 36.8063C83.2812 35.6891 84.1953 34.775 85.3125 34.775H93.4375C94.5547 34.775 95.4688 35.6891 95.4688 36.8063V44.9313C95.4688 46.0485 94.5547 46.9625 93.4375 46.9625Z"
          fill="white"
        />
        <path
          d="M93.4375 95.7125H85.3125C84.1953 95.7125 83.2812 94.7985 83.2812 93.6813C83.2812 92.5641 84.1953 91.65 85.3125 91.65H91.4062V85.5563C91.4062 84.4391 92.3203 83.525 93.4375 83.525C94.5547 83.525 95.4688 84.4391 95.4688 85.5563V93.6813C95.4688 94.7985 94.5547 95.7125 93.4375 95.7125Z"
          fill="white"
        />
        <path
          d="M44.6875 95.7125H36.5625C35.4453 95.7125 34.5312 94.7985 34.5312 93.6813V85.5563C34.5312 84.4391 35.4453 83.525 36.5625 83.525C37.6797 83.525 38.5938 84.4391 38.5938 85.5563V91.65H44.6875C45.8047 91.65 46.7188 92.5641 46.7188 93.6813C46.7188 94.7985 45.8047 95.7125 44.6875 95.7125Z"
          fill="white"
        />
        <path
          d="M71.0938 38.8375H58.9062C57.7891 38.8375 56.875 37.9235 56.875 36.8063C56.875 35.6891 57.7891 34.775 58.9062 34.775H71.0938C72.2109 34.775 73.125 35.6891 73.125 36.8063C73.125 37.9235 72.2109 38.8375 71.0938 38.8375Z"
          fill="white"
        />
        <path
          d="M71.0938 95.7125H58.9062C57.7891 95.7125 56.875 94.7985 56.875 93.6813C56.875 92.5641 57.7891 91.65 58.9062 91.65H71.0938C72.2109 91.65 73.125 92.5641 73.125 93.6813C73.125 94.7985 72.2109 95.7125 71.0938 95.7125Z"
          fill="white"
        />
        <path
          d="M93.4375 73.3688C92.3203 73.3688 91.4062 72.4547 91.4062 71.3375V59.15C91.4062 58.0328 92.3203 57.1188 93.4375 57.1188C94.5547 57.1188 95.4688 58.0328 95.4688 59.15V71.3375C95.4688 72.4547 94.5547 73.3688 93.4375 73.3688Z"
          fill="white"
        />
        <path
          d="M36.5625 73.3688C35.4453 73.3688 34.5312 72.4547 34.5312 71.3375V59.15C34.5312 58.0328 35.4453 57.1188 36.5625 57.1188C37.6797 57.1188 38.5938 58.0328 38.5938 59.15V71.3375C38.5938 72.4547 37.6797 73.3688 36.5625 73.3688Z"
          fill="white"
        />
      </svg>
    </>
  );
}
