/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const StyledContainer = styled.div<{
  isError?: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 1.6rem 0;
  margin-bottom: ${({ isError }) => (isError ? '3.2rem' : '1.6rem')};
`;

export const StyledLabel = styled.label`
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
`;

export const StyledErrorMessage = styled.span`
  color: rgb(200, 16, 46);
  font-gamily: Open Sans;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const StyledSelectContainer = styled.div`
  position: relative;
  height: 4.8rem;
`;

export const StyledSelectComponent = styled.button<{
  opened?: boolean;
  isError?: boolean;
}>`
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 100%;
  height: 4.8rem;
  border: ${({ isError, theme, opened }) => {
    if (!opened) {
      if (isError === undefined) return '1px solid var(--neutrals-400)';
      return isError
        ? '1px solid var(--error-500)'
        : `1px solid ${theme.vendor.colors.primary}`;
    }
  }};
  border-radius: 4px;
  background-color: #ffffff;
  padding-right: 1rem;
  cursor: pointer;
  outline: none;
  display: flex;
  grid-template-areas: 'select';
  align-items: center;
  &::after {
    margin-top: 0.2rem;
    content: url(/icons/chevron.svg);
    grid-area: select;
    justify-self: end;
    transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  &:focus,
  &:focus-within {
    ${({ theme, opened, isError }) => {
      if (opened) {
        return `border: 2px solid ${theme.vendor.colors.primary}`;
      }
      if (isError === undefined) {
        return `border: 1px solid var(--neutrals-400)`;
      }
      return isError
        ? `border:1px solid var(--error-500)`
        : `border: 1px solid ${theme.vendor.colors.primary}`;
    }};
  }
`;

export const StyledSelectContent = styled.span`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  grid-area: select;
  background: rgba(0, 0, 0, 0);
  padding: 1.2rem 0.8rem 1.2rem 1.2rem;
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;

  & span {
    padding: 0;
    margin: 0;
  }

  & span.d-label {
    max-width: 37rem;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & span.d-icon {
    padding: 0;
  }
`;

export const StyledOptions = styled.ul<{ opened?: boolean }>`
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: ${(props) => (props.opened ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  margin: 0;
  width: 100%;
  z-index: 50;
  padding: 0;
  position: absolute;
  list-style-type: none;
  background-color: #ffffff;
  border: 0px;
  border-radius: 4px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  display: grid;
  grid-template-columns: 100%;
`;

export const StyledOption = styled.li`
  padding: 1.2rem 0;
  text-indent: 1.6rem;
  &:hover {
    background-color: ${({ theme }) => theme.vendor.colors.light};
    color: var(--neutrals-500);
    cursor: pointer;
  }
  &:not(:last-child)::after {
    display: block;
    position: relative;
    top: 1.25rem;
    content: '';
    height: 1px;
    background-color: var(--neutrals-200);
  }
`;
