import axios, { AxiosInstance } from 'axios';
import mem from 'memoizee';
import { ERRORDETAILS } from './constants';
import { ERRORKEY } from '@vat/configuration';
import { AxiosError } from 'axios';
export interface IAppConfig {
  msBffBaseURL: string;
  timeout: number;
  env: string;
  apimKey: string;
  api: IAppApiConfig;
  psp: Record<string, any>;
}

export interface IAppApiConfig {
  openBankingRequest: IAppOpenBanking;
}

export interface IAppOpenBanking {
  basePath: string;
  init: string;
  result: string;
}

declare global {
  interface WindowWithEnv extends Window {
    _env_: IAppConfig;
  }
}

export default function axiosInstance(): AxiosInstance {
  return mem(
    (): AxiosInstance => {
      const axiosBack = axios.create({
        /* eslint no-underscore-dangle: 0 */
        baseURL: (window as unknown as WindowWithEnv)._env_?.msBffBaseURL,
        headers: {
          keyId: (window as unknown as WindowWithEnv)._env_?.apimKey,
        },
        timeout: 30000,
        timeoutErrorMessage: 'ECONNTIMEOUT',
      });

      axiosBack.interceptors.response.use(
        (response) => {
          return response;
        },
        (error: AxiosError) => {
          let errCode = error?.response?.status;
          let timeout = '';
          if (error?.response === undefined) {
            // eslint-disable-next-line prefer-promise-reject-errors
            errCode = 400;
          }
          // timeout
          if (error?.code === 'ECONNABORTED') {
            // eslint-disable-next-line prefer-promise-reject-errors
            timeout = ' en Timeout';
            errCode = 408;
          }
          const resp = error?.response?.data as any;
          localStorage.setItem(ERRORKEY, resp?.status ?? errCode);
          localStorage.setItem(
            ERRORDETAILS,
            `Page: ${window.location.href} , Service${timeout}: ${
              resp?.path ?? '-'
            }, Service Error: ${resp?.error ?? '-'}, Service Status Code: ${
              resp?.status ?? '-'
            }, Error Message: ${resp?.message ?? '-'}, $OtherReasons`
          );
          return Promise.reject(errCode);
        }
      );
      return axiosBack;
    },
    {
      primitive: true,
    }
  )();
}
