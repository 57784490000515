import {
  NumberInput,
  BubbleInfo,
  GenderInput,
  ButtonLink,
} from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';
import { useTranslation } from 'react-i18next';
import {
  ButtonActionWrapper,
  StyledBackButtonWrapper,
  StyledValidateButtonWrapper,
  IncomeDetailsInfoWrapper,
  BaseMonthIncomeNumberWrapper,
  IncomeWrapper,
} from './styles';
import useIncomeExpensesLongForm from './useIncomesExpensesLongForm';

const NB_OF_MONTHS_OPTIONS = ['12', '13', '14', '15', '16'];
const VALIDATE_ON_BLUR = true;
const VALIDATE_ON_CHANGE = true;
const replacement = JSON.stringify([
  { regexp: '[^0-9,.]', replacement: '' },
  { regexp: '[.]{1,}', replacement: ',' },
  { regexp: '[,]{1,}', replacement: ',' },
]);

const IncomesExpensesLongForm = () => {
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { t } = useTranslation();
  const {
    isActionLoading,
    formik,
    nbOfMonthIsInvalid,
    isIncomeFocused,
    isIncomeError,
    isNumberOfMonthsValid,
    isRentOrCreditError,
    isOngoingDebtFocused,
    isOngoingDebtError,
    isChildSupportError,
    isFormValid,
    goBackToStep,
    onSubmitclick,
    onIncomeFocus,
    onHideBubbleInfoIncome,
    onRentOrCreditFocus,
    onOngoingDebtFocus,
    onHideOutgoingDebt,
    onChildSupportFocus,
    onChangeIncome,
    onChangeRentOrCredit,
    onChangeOngoingDebt,
    onChangeChildSupport,
    onBlurRentOrCredit,
    onChildSupportBlur,
  } = useIncomeExpensesLongForm(opportunity, {
    validateOnBlur: VALIDATE_ON_BLUR,
    validateOnChange: VALIDATE_ON_CHANGE,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <IncomeWrapper id="income-wrapper" aria-invalid={isIncomeError}>
        <NumberInput
          id="income"
          errorMessage={formik.errors.income}
          label={t('incomeAndExpensesLong.income.label')}
          placeholder={t('incomeAndExpensesLong.income.placeholder')}
          onChange={onChangeIncome}
          value={formik.values.income}
          onBlur={onHideBubbleInfoIncome}
          onFocus={onIncomeFocus}
          isInvalid={isIncomeError}
          replacement={replacement}
          maxlength={5}
          withReset
        />
        {isIncomeFocused ? (
          <IncomeDetailsInfoWrapper>
            <BubbleInfo
              id="bubbleinfo-income"
              title={t('incomeAndExpensesLong.income.tooltip.title')}
              bubbleItems={[
                t('incomeAndExpensesLong.income.tooltip.salary'),
                t('incomeAndExpensesLong.income.tooltip.retirement'),
                t('incomeAndExpensesLong.income.tooltip.freelance'),
                t('incomeAndExpensesLong.income.tooltip.pensions'),
              ]}
              marker="default"
              isCustomedMarker={false}
              hasIcon={true}
            />
          </IncomeDetailsInfoWrapper>
        ) : (
          <></>
        )}
      </IncomeWrapper>
      {isIncomeFocused ||
      (Number(formik.values.income?.replace(' ', '').trim()) || 0) > 0 ? (
        <BaseMonthIncomeNumberWrapper
          id="nbOfMonths-wrapper"
          aria-invalid={!isNumberOfMonthsValid}
        >
          <GenderInput
            id="nbOfMonths"
            label={t('incomeAndExpensesLong.nbOfMonths.label')}
            value={formik.values.nbOfMonths}
            error-message={formik.errors.nbOfMonths}
            invalid={nbOfMonthIsInvalid}
            onSelectedElementChange={formik.handleChange}
            hasFocus
          >
            {NB_OF_MONTHS_OPTIONS.map((value) => (
              <selected-button
                value={value}
                secondary="true"
                selected={
                  Number(formik.values.nbOfMonths?.replace(' ', '').trim()) ===
                  Number(value)
                }
                key={`month_number_${value}`}
              >
                {value}
              </selected-button>
            ))}
          </GenderInput>
        </BaseMonthIncomeNumberWrapper>
      ) : (
        <></>
      )}
      <IncomeWrapper
        id="rentOrCredit-wrapper"
        aria-invalid={isRentOrCreditError}
        onFocus={onRentOrCreditFocus}
      >
        <NumberInput
          id="rentOrCredit"
          label={t('incomeAndExpensesLong.rentOrCredit.label')}
          placeholder={t('incomeAndExpensesLong.rentOrCredit.placeholder')}
          onChange={onChangeRentOrCredit}
          value={formik.values.rentOrCredit}
          errorMessage={formik.errors.rentOrCredit}
          isInvalid={isRentOrCreditError}
          onFocus={onRentOrCreditFocus}
          replacement={replacement}
          onBlur={onBlurRentOrCredit}
          maxlength={5}
          with-reset
        />
      </IncomeWrapper>
      <IncomeWrapper id="ongoingDebt-wrapper" aria-invalid={isOngoingDebtError}>
        <NumberInput
          id="ongoingDebt"
          label={t('incomeAndExpensesLong.ongoingDebt.label')}
          placeholder={t('incomeAndExpensesLong.ongoingDebt.placeholder')}
          onChange={onChangeOngoingDebt}
          value={formik.values.ongoingDebt}
          errorMessage={formik.errors.ongoingDebt}
          isInvalid={isOngoingDebtError}
          onBlur={onHideOutgoingDebt}
          onFocus={onOngoingDebtFocus}
          replacement={replacement}
          maxlength={5}
          with-reset
        />
        {isOngoingDebtFocused ? (
          <IncomeDetailsInfoWrapper>
            <BubbleInfo
              id="bubbleinfo-income"
              title={t('incomeAndExpensesLong.ongoingDebt.tooltip.title')}
              bubbleItems={[
                t('incomeAndExpensesLong.ongoingDebt.tooltip.car'),
                t('incomeAndExpensesLong.ongoingDebt.tooltip.secondaryHome'),
                t('incomeAndExpensesLong.ongoingDebt.tooltip.consumption'),
              ]}
              marker="default"
              isCustomedMarker={false}
              hasIcon={true}
            />
          </IncomeDetailsInfoWrapper>
        ) : (
          <></>
        )}
      </IncomeWrapper>
      <IncomeWrapper
        id="childSupport-wrapper"
        aria-invalid={isChildSupportError}
      >
        <NumberInput
          id="childSupport"
          label={t('incomeAndExpensesLong.childSupport.label')}
          placeholder={t('incomeAndExpensesLong.childSupport.placeholder')}
          onChange={onChangeChildSupport}
          value={formik.values.childSupport}
          errorMessage={formik.errors.childSupport}
          isInvalid={isChildSupportError}
          onFocus={onChildSupportFocus}
          onBlur={onChildSupportBlur}
          replacement={replacement}
          maxlength={5}
          with-reset
        />
      </IncomeWrapper>
      <ButtonActionWrapper>
        <StyledValidateButtonWrapper
          type="submit"
          onClick={(e) => onSubmitclick(e as never)}
          $isDisabled={!isFormValid}
          id="solva-long-validate-button"
          className={!isFormValid ? 'isDisabled' : ''}
        >
          {isActionLoading ? (
            <StyledButtonLoader
              isLoading
              isTextInline
              isHideBackground={false}
            />
          ) : (
            t('common.validate')
          )}
        </StyledValidateButtonWrapper>
        <StyledBackButtonWrapper>
          <ButtonWrapper className="back-button-wrapper">
            <ButtonLink
              label={t('common.back')}
              onClick={goBackToStep}
              id="back-button"
            />
          </ButtonWrapper>
        </StyledBackButtonWrapper>
      </ButtonActionWrapper>
    </form>
  );
};

export default IncomesExpensesLongForm;
