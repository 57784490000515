import { LabelWithStatus, Wrapperlabel } from './styles';

interface ILabelInputProps {
  children?: React.ReactNode;
  className?: string;
}

export const LabelInput = ({
  children,
  className = '',
}: ILabelInputProps): React.ReactElement => (
  <Wrapperlabel className={className}>
    <LabelWithStatus>{children}</LabelWithStatus>
  </Wrapperlabel>
);

export default LabelInput;
