import { Title } from '@sweb-front/components';
import { NextButtonWrapper } from '@sweb-front/styles';
import { TAB, DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: start;

  & .space {
    height: 4rem;
  }
`;
export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  text-align: left;
  font-family: 'Open Sans Regular';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  @media screen and ${TAB} {
    text-align: center;
    margin: 0 2.5rem;
  }
  @media screen and ${DESKTOP} {
    text-align: center;
    margin: 0 6rem;
  }
`;

export const TitleCmp = styled(Title)`
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: 'Ubuntu Bold';
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const CustomizedButtonWrapper = styled(NextButtonWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & base-button {
    max-width: 30rem;
    flex-grow: 1;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
    --color-button: var(--neutrals-0);
    background: var(--main-green-500);
    border: 1px solid var(--main-green-500);
  }

  & base-button:hover {
    color: var(--neutrals-0);
  }
`;
