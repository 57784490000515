import { isStringEmpty } from '@sweb-front/utils';
import ErrorDesk from './ErrorDeskImage';

import {
  TitleCmp,
  ContentWrapper,
  ErrorPageWrapper,
  CenterWrapper,
} from './styles';
import useErrorPage from './useErrorPage';
import { isCetelemCompany, useEngineLoader } from '@vat/utils';

const ErrorPage = () => {
  const { getErrorMessage } = useErrorPage();
  const { title, firstParagraph, secondParagraph } = getErrorMessage();
  const companyCd = useEngineLoader();

  return (
    <ErrorPageWrapper data-testid="ErrorPageWrapper">
      {isCetelemCompany(companyCd) ? (
        <ErrorDesk />
      ) : (
        <div className="space"></div>
      )}
      <CenterWrapper>
        <ContentWrapper>
          <TitleCmp>{title}</TitleCmp>
          <span>{firstParagraph}</span>
          {!isStringEmpty(secondParagraph) && <span>{secondParagraph}</span>}
        </ContentWrapper>
      </CenterWrapper>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
