import { CustomedBubbleInfo, Section, Title } from '@sweb-front/components';
import { NextButtonWrapper } from '@sweb-front/styles';
import styled from 'styled-components';

export const SectionWrapper = styled(Section)`
  position: relative;
  padding: 0;
  color: var(--neutrals-500);
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
  img {
    self-align: center;
  }
`;
export const ImgWrapper = styled.div`
  padding: 0;
  marign: 0;
  margin-top: 1.2rem;
  position: relative;
  height: 14.8rem;
  text-align: center;
  align-items: center;
`;

export const SVGIconWrapper = styled.div`
  z-index: 10;
  display: inline-flex;
  margin: 0;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: -6.8rem;
  margin-left: 10rem;
  width: 6rem;
  height: 6rem;
  & base-icon {
    width: 6rem;
    height: 6rem;
    --icon-width: 6rem;
    --icon-height: 6rem;
    --color-icon: var(--main-green-500);
  }
`;

export const ContentWrapper = styled.div`
  margin: 1.6rem 0;
  text-align: center;
`;

export const TitleCmp = styled(Title)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  color: var(--neutrals-700);
`;

export const DocumentReceived = styled.div`
  margin-bottom: 1.2rem;
  text-align: left;
  & span {
    font-family: Open Sans Bold;
  }
`;

export const Paragraph = styled.p`
  font-family: 'Open Sans';
  margin-top: 0.4rem;
`;

export const FirstParagraph = styled(Paragraph)`
  margin-top: 1.2rem;
`;

export const ReceptionParagraph = styled(Paragraph)`
  margin-top: 1.6rem;
`;

export const AcceptedLoan = styled.div`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  font-family: 'Open Sans Bold';
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: center;
`;

export const CustomizedValidateButtonWrapper = styled(NextButtonWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.4rem 0rem;

  & base-button {
    flex-grow: 1;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: 'Open Sans Bold';
    --color-button: var(--neutrals-0);
    background: ${({ theme }) => theme.vendor.colors.primary};
    border: 1px solid var(--main-green-500);
    height: 5rem;
    padding: 1.1rem 1.2rem 1.2rem 1.2rem;
    max-width: 40rem;

    &:hover {
      background: ${({ theme }) => theme.vendor.colors.secondary};
      color: var(--neutrals-0);
    }
  }
`;

export const DocumentInPersonalAccountParagraph = styled.div`
  font-family: 'Open Sans Bold';
  margin-top: 3.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(-neutrals-700);
  text-align: left;
`;

export const DocumentInPersonalAccountParagraphExplanation = styled.div`
  margin-top: 1.6rem;
  text-align: left;
  &:last-of-type {
    margin-bottom: 3.2rem;
  }
`;

export const CustomedBubbleInfoCmp = styled(CustomedBubbleInfo)`
  margin-bottom: 2.4rem;
  & .blue-color {
    color: var(--blue-500);
  }
`;

export const AsteriskWrapper = styled.div`
  text-align: left;
  margin-top: 3.2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 1.6rem;
  & a {
    text-decoration: none;
    color: inherit;
  }
`;
