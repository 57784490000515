import CardItemContentWrapper, { ValueWrapper } from './styles';

export interface ICardItemContentProps {
  title?: string;
  description?: React.ReactNode;
}

const CardItemContent = ({ title, description }: ICardItemContentProps) => (
  <CardItemContentWrapper>
    {title && <div className="title">{title}</div>}
    {description && <ValueWrapper>{description}</ValueWrapper>}
  </CardItemContentWrapper>
);

export default CardItemContent;
