import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBankCardPaymentResponse, ICardPayment, MonextResponse, } from '@sweb-front/types';

const getInitialState = () => {
  return { state: {} } as {
    state: {
      cardPayement: ICardPayment;
      bankPayementResponse: IBankCardPaymentResponse;
      monextResponse: MonextResponse;
    };
  };
};

// Initial State
export const initialState = getInitialState();

const cardPaymentSlice = createSlice({
  name: 'carPayment',
  initialState,
  reducers: {
    clear(cardPaymentState) {
      cardPaymentState.state = getInitialState().state;
    },
    setCardPayment(cardPaymentState, action: PayloadAction<ICardPayment>) {
      cardPaymentState.state.cardPayement = action.payload;
    },
    setMonextResponse(cardPayementState, action: PayloadAction<MonextResponse>) {
      cardPayementState.state.monextResponse = action.payload;
    },
    setSEDecision(
      cardPaymentState,
      action: PayloadAction<IBankCardPaymentResponse>
    ) {
      cardPaymentState.state.bankPayementResponse = action.payload;
    },
  },
});

export const selectCardPaymentState = (cardPayment: {
  cardPayment: { state: ICardPayment };
}) => cardPayment.cardPayment.state;

export const { setCardPayment, clear, setSEDecision, setMonextResponse } = cardPaymentSlice.actions;

export default cardPaymentSlice.reducer;
