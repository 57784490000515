import { DESKTOP, TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const RedirectionComponentContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  border-top: 0.4rem solid ${({ theme }) => theme.vendor.colors.primary};

  box-shadow: 0rem 0.2rem 0.2rem 0.2rem var(--neutrals-200);
  background-color: #fff;
  padding: 0rem 1.6rem 1.6rem 1.6rem;

  @media screen and ${TAB}, ${DESKTOP} {
    position: fixed;
    width: 37.5rem;
    height: 14.5rem;
    right: 2rem;
    bottom: 2rem;
    border-radius: 0.4rem;
  }

  & .clickhere {
    margin-top: 1rem;
    font-family: 'Ubuntu';
    line-height: 1.8rem;
    font-size: 1.2rem;

    & span {
      color: ${({ theme }) => theme.vendor.colors.primary};
      cursor: pointer;
    }
  }
`;

export const RedirectionComponentContent = styled.div`
  & .title {
    line-height: 2.4rem;
    font-size: 1.6rem;
    font-family: 'Open Sans Bold';
    margin: 1.6rem 0;
  }

  & .message {
    line-height: 1.8rem;
    font-size: 1.4rem;
    font-family: 'Ubuntu';
    margin: 1.6rem 0;
  }
`;

export const ProgressBarComponent = styled.div<{
  progress: number;
}>`
  width: 100%;
  height: 0.8rem;
  margin-top: 1.8rem;
  background-color: #d9d9d9;
  display: flex;
  border-radius: 0.4rem;
  overflow: hidden;

  @media screen and ${TAB}, ${DESKTOP} {
    border-radius: 0.4rem;
  }

  & div {
    width: 100%;
    border-radius: 0.4rem;
    transform: ${({ progress }) => `scaleX(${progress}%)`};
    transform-origin: 0 0;
    transition: transform 0.1s linear;
    background-color: ${({ theme }) => theme.vendor.colors.primary};
  }
`;
