import styled from 'styled-components';

interface IStyleProps {
  isTop?: boolean;
  isBottom?: boolean;
  isSpecificStyle?: boolean;
  isStyleText?: boolean;
  withScroll?: boolean;
}

const listHeight = 62.1;

export const Wrapper = styled.div<IStyleProps>`
  max-height: ${({ withScroll }) => (withScroll ? '67.2rem' : '100%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem;

  &:after {
    visibility: ${({ isSpecificStyle }) =>
      isSpecificStyle ? 'visible' : 'hidden'};
    position: absolute;
    top: ${`calc(${listHeight}rem * 92 / 100)`};
    content: '';
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      ${({ theme }) => theme.variables.colors.chartColors.white}
    );
    width: calc(100% - 5.5rem);
    height: ${({ isBottom }) => (isBottom ? '0' : '15%')};
    pointer-events: none;
    z-index: 1;
  }

  .ps--active-x > .ps__rail-x {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark};
`;

export const StyledTitle = styled.h2`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: 'Ubuntu Bold';
`;

export const TitleWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconWithTitleWrapper = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 10px;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark};
`;

export const TitleWithIconWrapper = styled.h2`
  text-transform: uppercase;
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  & base-button {
    background: ${({ theme }) => theme.vendor.colors.primary};
    border: 1px solid ${({ theme }) => theme.vendor.colors.primary};

    &:hover {
      background: ${({ theme }) => theme.vendor.colors.secondary};
    }
  }
`;

export const StyledText = styled.div<IStyleProps>`
  margin: 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  text-align: center;
  margin-bottom: ${({ theme, isSpecificStyle }) =>
    isSpecificStyle ? '0px' : `${theme.variables.layout.spacing.spacing_l}`};
`;
