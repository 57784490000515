import styled from 'styled-components';
import BasicLoader from './../BasicLoader/BasicLoader';

export const FileDownloadWrapper = styled.a<{
  isLoading: boolean;
}>`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
  text-decoration: underline;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  margin-bottom: 0.8rem;

  & base-icon {
    margin-right: 0.6rem;
    margin-top: 0.3rem;
    width: 1.6rem;
    height: 2rem;
    --color-icon: ${({ theme }) => theme.vendor.colors.primary};
  }
`;

export const StyledLoader = styled(BasicLoader)`
  margin-top: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
`;
