import { useTheme } from 'styled-components';

/**
 * @hook  Design systems puts together the border and the color of the checkbox in the same state
 * We need to set the check free from the state of the validation
 *
 */
export default function useOverrideShadowElementStyle() {
  const theme = useTheme();

  requestAnimationFrame(() => {
    computeShadowRootElement(
      'combo-input, base-input, date-input',
      `svg[id="valid"] path {
        fill: var(--success-500);
      }`
    );

    computeShadowRootElement(
      'numeric-input',
      `selected-button[selected] {
        --ripple-color: ${theme.vendor.colors.light}; 
        color: ${theme.vendor.colors.primary}; 
        background: ${theme.vendor.colors.light}; 
        border: 1px solid ${theme.vendor.colors.primary};
      }`
    );

    computeShadowRootElement(
      'gender-input radio-button-card',
      `base-radio-button[checked] {
        --color-selected: ${theme.vendor.colors.primary}; 
        --background-color-selected:${theme.vendor.colors.primary};        
      }

      base-radio-button.hover {
        --color-selected: ${theme.vendor.colors.primary}; 
        --background-color-selected: ${theme.vendor.colors.primary};
      }
      `
    );

    computeShadowRootElement(
      'combo-input',
      `combo-item:hover {
        background: ${theme.vendor.colors.light};
      }`
    );
  });

  const manageNumericInput = (id?: string) => {
    const numericInputElement = document.querySelector(
      `numeric-input[id="${id}"]`
    ) as HTMLInputElement;
    const numericalValue = numericInputElement?.value;
    const shadowRoot = numericInputElement?.shadowRoot;
    const style = document.createElement('style');
    if (parseInt(`${numericalValue}`.replace(/ /, '')) > 0) {
      style.textContent = `
        selected-button {
          background: none;
        }
      `;
    }
    shadowRoot?.appendChild(style);
  };

  return { manageNumericInput, computeShadowRootElement };
}

const computeShadowRootElement = (eltType: string, inlineStyle: string) => {
  const eltTags = document.querySelectorAll(eltType);
  const elementList = Array.from(eltTags ?? []) as HTMLInputElement[];
  for (let elt of elementList) {
    const shadowRoot = elt?.shadowRoot;
    const style = document.createElement('style');
    style.textContent = inlineStyle;
    shadowRoot?.appendChild(style);
  }
};
