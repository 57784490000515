import styled from 'styled-components';

export const LabelWrapper = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const SVGIconWrapper = styled.div`
  margin: 0;
  padding: 0;
  margin-left: 0.4rem;

  & base-icon {
    --color-icon: ${({ theme }) => theme.vendor.colors.primary};
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const BackToWebMerchantWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
`;
