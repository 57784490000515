import styled from 'styled-components';
import { DESKTOP, TAB } from '@sweb-front/utils';

const CenteredContent = styled.div`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.6rem;
  position: relative;

  @media screen and ${TAB} {
    padding: 0 15.2rem;
  }
  @media screen and ${DESKTOP} {
    padding: 0 1.6rem;
  }

  & base-input,
  date-input,
  combo-input,
  numeric-input {
    --focus-color: ${({ theme }) => theme.vendor.colors.primary};
    --valid-color: ${({ theme }) => theme.vendor.colors.primary};
  }

  & gender-input {
    & selected-button {
      --ripple-color: ${({ theme }) => theme.vendor.colors.light};
    }

    & selected-button[selected] {
      color: ${({ theme }) => theme.vendor.colors.primary};
      background: ${({ theme }) => theme.vendor.colors.light};
      border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
    }
  }

  & gender-input radio-button-card {
    &[selected] {
      background: ${({ theme }) => theme.vendor.colors.light};
    }
  }
`;

export interface ICenteringProps {
  children?: React.ReactNode;
  className?: string;
}

const Centering = ({
  children,
  className = '',
}: ICenteringProps): React.ReactElement => {
  return <CenteredContent className={className}>{children}</CenteredContent>;
};

export default Centering;
