import { t } from 'i18next';
import {
  ButtonWrapper,
  CustomerInformationnCardWrapper,
  ImgWrapper,
  InfoWrapper,
  MessageWrapper,
  StyledButton,
  TitleWrapper,
  LeaveActionLinkWrapper,
  ContentHeader,
} from './styles';
import { isStringEmpty, trackEvent } from '@sweb-front/utils';

export interface ICustomerInformationProps {
  props: {
    id?: string;
    firstName: string;
    title: string;
    lastName: string;
    adsress: string;
    zipCode: string;
    city: string;
    counrty: string;
    email: string;
    cellPhone: string;
    description: string;
    buttonLabel?: string;
    withLinkButton: boolean;
    onButtonClick: () => void;
  };
}

const trackEventEditButton = () => {
  trackEvent({ event: 'confirmExit' });
};

const CustomerInformationCard = ({
  props: { buttonLabel = '', withLinkButton = false, ...props },
}: ICustomerInformationProps) => {
  const onEditInformations = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    trackEventEditButton();
    props.onButtonClick();
  };
  const label = isStringEmpty(buttonLabel)
    ? t('customerInformation.modify')
    : buttonLabel;
  return (
    <CustomerInformationnCardWrapper id={props.id}>
      <div className="content">
        <ContentHeader className="content-header">
          <ImgWrapper>
            <base-icon icon="warning-Fill" />
          </ImgWrapper>
          <TitleWrapper>{props.title}</TitleWrapper>
        </ContentHeader>
        <MessageWrapper>{props.description}</MessageWrapper>
        <InfoWrapper>
          <div>
            {props.firstName} {props.lastName}
          </div>
          <div>{props.adsress}</div>
          <div>
            {props.zipCode} {props.city} {props.counrty}
          </div>
          <div>{props.email}</div>
          <div>{props.cellPhone}</div>
        </InfoWrapper>

        {!withLinkButton && (
          <ButtonWrapper>
            <StyledButton
              onClick={onEditInformations}
              id="contract-download-button"
            >
              {label}
              <base-icon icon="arrow-right-short" />
            </StyledButton>
          </ButtonWrapper>
        )}

        {withLinkButton && (
          <LeaveActionLinkWrapper
            text={label}
            onClick={onEditInformations}
            className="edit-info-wbm"
          />
        )}
      </div>
    </CustomerInformationnCardWrapper>
  );
};

export default CustomerInformationCard;
