import { ERRORKEY } from '@vat/configuration';
import axios, { AxiosError, AxiosInstance } from 'axios';
import mem from 'memoizee';

export default function axiosInstance(): AxiosInstance {
  return mem(
    (): AxiosInstance => {
      localStorage.removeItem(ERRORKEY);
      const axiosBack = axios.create({
        /* eslint no-underscore-dangle: 0 */
        baseURL: (window as unknown as WindowWithEnv)._env_?.msBffBaseURL,
        headers: {
          keyId: (window as unknown as WindowWithEnv)._env_?.apimKey,
        },
        timeout: 30000,
        timeoutErrorMessage: 'ECONNTIMEOUT',
      });

      axiosBack.interceptors.response.use(
        (configRequest) => {
          const withReqId = { ...configRequest };
          return withReqId;
        },
        (error: AxiosError) => {
          let errCode = error?.response?.status;
          if (error?.response === undefined) {
            // eslint-disable-next-line prefer-promise-reject-errors
            errCode = 400;
          }
          // timeout
          if (error?.code === 'ECONNABORTED') {
            // eslint-disable-next-line prefer-promise-reject-errors
            errCode = 408;
          }
          localStorage.setItem(ERRORKEY, `${errCode}`);
          return Promise.reject(errCode);
        }
      );

      return axiosBack;
    },
    {
      primitive: true,
    }
  )();
}
