import styled from 'styled-components';

export const StyledBackButton = styled.button`
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-family: 'Open Sans';
  font-weight: bold;
  letter-spacing: 0;
  text-align: end;
  display: grid;
  grid-template-areas: 'a b';
  align-items: end;
  width: 10rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
  width: 10rem;
`;

export const SVGIconWrapper = styled.div<{
  id?: string;
  invalid?: boolean | null;
  validity?: boolean | null;
}>`
  & base-icon {
    margin-right: 0.4rem;
    --color-icon: ${({ theme }) => theme.vendor.colors.primary};
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
  }
`;
