import { Section, BaseSpinner } from '@sweb-front/components';
import { LoadingWrapper } from '@sweb-front/styles';

import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import {
  PageLoadingContext,
  useCloseOpportunity,
  useManagingExternalApp,
} from '@vat/utils';
import { Monext3DsResponse } from '@sweb-front/types';
import {
  getActiveStep,
  getNestedStateRequest,
  selectNavigation,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { SpinnerLabel, SpinnerLongText } from './styles';

import { DOAUTHORIZATION, ERRORKEY, ERRORPAGE } from '@vat/configuration';
import { useNavigate } from 'react-router-dom';
import { trackCustomAction } from '@sweb-front/utils';

const Redirection = () => {
  const { t } = useTranslation();
  const updatePageIsLoading = useContext(PageLoadingContext);
  const externalAppSteps = useAppSelector(selectNavigation);
  const navigate = useNavigate();
  const { updateAbortErrorMessage } = useCloseOpportunity();

  const externalActiveStep = useMemo(
    () => getActiveStep(externalAppSteps?.steps),
    [getActiveStep, externalAppSteps]
  );

  const {
    finalizeGlobalLoanRequest,
    executeCreateECard,
    executeFinalInstantDecision,
  } = useManagingExternalApp();

  const params = useMemo(
    () => externalActiveStep?.params,
    [externalAppSteps, externalActiveStep?.params]
  );

  const monext3dsResponse =
    externalActiveStep?.params as unknown as Monext3DsResponse;
  const dispatch = useAppDispatch();

  const lastRequestToExecute = useMemo(
    () => getNestedStateRequest(externalAppSteps),
    [getNestedStateRequest, externalAppSteps]
  );

  trackCustomAction(
    `We are in redirection page in the case we don't get the threeDsRef from monext`
  );

  useEffect(() => {
    // doAuth
    if (
      externalActiveStep?.externalAppName === 'authorization' &&
      monext3dsResponse?.threeDsRef
    ) {
      trackCustomAction(
        `${JSON.stringify(monext3dsResponse)} in Redirection page`
      );
      if (lastRequestToExecute?.request_1 === false) {
        finalizeGlobalLoanRequest({ ...params, request_1: true });
      } else if (lastRequestToExecute?.request_2 === false) {
        executeCreateECard(localStorage.getItem('token'), params)
          .then(() =>
            executeFinalInstantDecision({ ...params, request_2: true })
          )
          .catch(() => {
            updateAbortErrorMessage(`Service de création e-card KO`);
            dispatch(
              updateSteps({
                externalAppName: 'authorization',
                params,
                isErrorHappened: true,
                waitingPagePath:
                  window.location.pathname + window.location.search,
              })
            );
            dispatch(
              updateParcoursNavigation({
                name: DOAUTHORIZATION,
                actionPageDone: false,
                params: {
                  ...params,
                  virtual: true,
                  hasError: true,
                },
              })
            );
            navigate(ERRORPAGE, {
              replace: true,
            });
          });
      } else if (lastRequestToExecute?.request_3 === false) {
        executeFinalInstantDecision({ ...params });
      } else {
        updateAbortErrorMessage(
          'An error occured at the end of doAuthorization that cannot be treated'
        );
        localStorage.setItem(ERRORKEY, 'error-etape-doauth');
        navigate(ERRORPAGE, {
          replace: true,
        });
      }
    } else {
      updateAbortErrorMessage(
        'An error occured in the journey, we cant resolve the data from monext'
      );
      localStorage.setItem(ERRORKEY, 'error-etapte-3DS');
      navigate(ERRORPAGE, {
        replace: true,
      });
    }
    updatePageIsLoading(false);
  }, []);

  return (
    <Section>
      <LoadingWrapper>
        <BaseSpinner size="large" />
        <SpinnerLabel>{t('waitingPage.label')}</SpinnerLabel>
        <SpinnerLongText>{t('waitingPage.longText')}</SpinnerLongText>
      </LoadingWrapper>
    </Section>
  );
};

export default Redirection;
