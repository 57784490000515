import { WrapperLoader } from './styles';

export interface ILoaderProps {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  message?: string;
  isTextInline?: boolean;
  isHideBackground?: boolean;
  animationDuration?: number;
}

const Loader = ({
  className = '',
  isTextInline = false,
  message,
  children,
}: ILoaderProps): React.ReactElement => (
  <WrapperLoader
    className={className}
    $isTextInline={isTextInline}
    data-testid="app-loader"
  >
    <div className="loader-wrapper">
      <div className={`loader with-theme`}></div>
      <div className={`label-loader with-theme`}>{message}</div>
    </div>

    {children}
  </WrapperLoader>
);

export default Loader;
