export const cetelem = {
  logo: 'cetelem',
  colors: {
    primary: 'var(--main-green-500)',
    secondary: 'var(--main-green-600)', // progressbar, check sur les boutons, bg button, accès site marchand, checkbox kyc, loading
    title: 'var(--neutrals-700)',
    light: 'var(--main-green-50)',
    medium: '#d1ead4',
    hover: '#3A913F',
    fieldtext: 'var(--medium-grey)',
    lightest: '#EFF4F2',
  },
  colorsDemat: {
    breadCrumbs: '#3A913F',
    titlesText: '#3A913F',
    nextButton: '#3A913F',
    elementsColor: '#3A913F',
    backGroundColor: '#E8F5E9',
    logosColor: '#3A913F',
    fieldtext: 'var(--medium-grey)',
  },
};

export const cafineo = {
  logo: 'cafineo',
  colors: {
    primary: 'var(--main-blue-500)', // when click on button (500)
    secondary: 'var(--main-blue-600)', // progressbar, check sur les boutons, bg button, accès site marchand, checkbox kyc, loading
    title: 'var(--neutrals-700)', // each page title color
    light: 'var(--main-blue-50)', // civility background when selected
    hover: 'var(--main-blue-600)',
    lightest: '#E5F5FD',
    medium: 'var(--main-blue-200)',
  },
};
